import { useParams } from "react-router-dom"
import Breadcrumbs from "../../molecules/Breadcrumbs/Breadcrumbs";
import FieldGroup from "../../organisms/FieldGroup/FieldGroup";
import { useEffect, useState } from "react";
import axios from "axios";
import { RequireAuth, useAuth } from "../LoginPage";

const BURIPASS_TOKENINFO_URL = 'https://staging.pass.buri.dev/oauth/token/info';

const appId = 'c46d7805-d4ec-4b30-8154-7c52326296a7';
const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';

const defaultFieldGroup = {
    id: '',
    name: 'Null',
    order_id: 0,
    application_id: appId,
    created_at: '',
    updated_at: ''
}

export default function FieldGroupDetailPage() {
    let auth = useAuth();
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [fieldGroup, setFieldGroup] = useState(defaultFieldGroup);
    const [application_uuid, setApplicationUUID] = useState("");

    function loadFieldGroup() {
        setLoading(true);
        if (auth.access_token){
            const headers = {
                'Authorization': `Bearer ${auth.access_token}`,
            };
            axios.get(
            `${RECORDS_RAILS_URL}/field_groups/${id}`, 
            { headers })
            .then((response) => {
                const gotFieldGroup = response.data;
                setFieldGroup(gotFieldGroup);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        if (auth.access_token){
          const headers = {
            'Authorization': `Bearer ${auth.access_token}`,
          };
          axios.get(BURIPASS_TOKENINFO_URL, { headers }).then((response) => {
            const pass_data = response.data;
            setApplicationUUID(pass_data.application.uuid);
          }).catch(function(error) {console.log(error)});
        }
    }, [application_uuid]);
    
    useEffect(() => {
        loadFieldGroup();
    }, [id]);

    return (
        <RequireAuth>
            <div className="m-4 h-full">
                <Breadcrumbs></Breadcrumbs>

                <div className="flex justify-between mb-4">
                    <div className="flex text-2xl">
                        <h2 className='text-gray-700 font-bold'>Field Group Details</h2>
                    </div>
                </div>

                {loading ? <p>Loading...</p> : (
                    <FieldGroup
                        key={id}
                        field_group_id={id}
                        field_group_name={fieldGroup.name}
                        order_id={fieldGroup.order_id}
                        application_id={fieldGroup.application_id}
                        created_at={fieldGroup.created_at}
                        updated_at={fieldGroup.updated_at}
                        loadParent={loadFieldGroup}
                        onFieldGroupDetail={true}
                    />)
                }

            </div>
        </RequireAuth>
    )
}