import React from "react";
import { Link } from "react-router-dom";
import { AuthStatus } from "../../pages/LoginPage";
import { Icon } from "../../atoms/Icon/Icon";

export default function LeftNavbar() {
    return (
        <nav className="pt-4 min-h-screen h-full" style={{backgroundColor: '#4B1F97'}}>
          <ul className='flex flex-col gap-2'>
            <li className="text-2xl mx-auto" style={{color: '#A68CFD'}}>
              <Link to="/">
                <Icon name="home"></Icon>
              </Link>
            </li>
            <li className="text-2xl mx-auto" style={{color: '#A68CFD'}}>
              <Icon name="person"></Icon>
            </li>
            <li className="text-2xl mx-auto" style={{color: '#A68CFD'}}>
              <Icon name="settings"></Icon>
            </li>
            <li className="text-2xl mx-auto" style={{color: '#A68CFD'}}>
              <Icon name="people"></Icon>
            </li>
          </ul>
        </nav>
    );
};