import React from "react";

// Needed for tailwind color generation. Huhu
const ColorMap = {
  primary:
    "bg-primary-500 text-white hover:bg-primary-600 focus:bg-primary-600",
  secondary:
    "bg-secondary-500 text-white hover:bg-secondary-600 focus:bg-secondary-600",
  secondaryOutline:
    "outline outline-1 outline-secondary-500 text-secondary-500 hover:outline-secondary-700 focus:outline-secondary-700",
  warning:
    "bg-warning-500 text-white hover:bg-warning-600 focus:bg-warning-600",
  error: "bg-error-500 text-white hover:bg-error-600 focus:bg-error-600",
  success:
    "bg-success-500 text-white hover:bg-success-600 focus:bg-success-600",
  primaryInverted: "bg-white text-primary-500",
  primaryOutline:
    "outline outline-1 outline-primary-500 text-primary-500 hover:outline-primary-700 focus:outline-primary-700",
  mutedInverted: "text-gray-400 bg-transparent hover:text-gray-700",
  helixPay: "bg-orange-500 text-white hover:bg-orange-600 focus:bg-orange-600",
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: keyof typeof ColorMap;
  width?: string;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  color = "primary",
  children,
  width,
  ...props
}: ButtonProps) => {
  let className = `flex items-center justify-center
    uppercase text-center py-2 px-4 
    rounded cursor-pointer
    disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
    transition ${width} ${ColorMap[color]} ${props.className}`;

  return (
    <button type="button" {...props} className={className}>
      {children}
    </button>
  );
};
