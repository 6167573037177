import React, { useEffect } from "react";
import { Icon } from "../../atoms/Icon/Icon";

export type ToastStatus = "success" | "error" | "info" | "warning";

const handleToastColor = (status: ToastStatus) => {
  switch (status) {
    case "success":
      return "bg-success-100 text-success-700";
    case "error":
      return "bg-error-100 text-error-700";
    case "info":
      return "bg-info-100 text-info-700";
    case "warning":
      return "bg-warning-100 text-warning-700";
  }
};

const handleToastIcon = (status: ToastStatus) => {
  switch (status) {
    case "success":
      return "check_circle";
    case "error":
      return "cancel";
    case "info":
      return "info";
    case "warning":
      return "warning";
  }
};

export interface ToastProps {
  title?: string;
  message: string;
  closeDelay?: number;
  toastStatus?: ToastStatus;
  onClose?: () => void;
}

export function Toast({
  title,
  message,
  onClose,
  toastStatus = "success",
  closeDelay = 5000,
}: ToastProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose?.();
    }, closeDelay);
    return () => clearTimeout(timer);
  }, [onClose, closeDelay]);

  return (
    <div
      className={`fixed top-20 left-1/2 transform -translate-x-1/2 p-4 flex justify-between gap-4 z-50 rounded ${handleToastColor(
        toastStatus
      )}`}
    >
      <span>
        <Icon name={handleToastIcon(toastStatus)} className="text-2xl"></Icon>
      </span>

      <div>
        <h3 className="text-lg font-bold">{title}</h3>

        {message}
      </div>

      <div>
        <button type="button" className="" onClick={onClose}>
          <Icon name="close"></Icon>
        </button>
      </div>
    </div>
  );
}