import { useState } from "react";
import { Icon } from "../../atoms/Icon/Icon";
import Modal from "../../molecules/Modal/Modal";
import AddFieldModal from "../AddFieldModal/AddFieldModal";

interface AddFieldProps {
    width: string;
    onAddFieldRequest: any;
}

export default function AddField({onAddFieldRequest, width}: AddFieldProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onModalCloseRequest() {
    setIsModalOpen(false);
  };

  const fullWidth = (
    <div className="flex w-full modal-parent">
      <button className="flex justify-center w-full rounded bg-purple-100 text-purple-700 mt-4 mb-2 py-0.5 text-sm"
        type="button" onClick={() => setIsModalOpen(true)}>
        <Icon className="mr-1" name="add_circle"></Icon>
        <div className="ml-1">ADD FIELD</div>
      </button>
      <Modal isOpen={isModalOpen} onCloseRequest={onModalCloseRequest} modalPos="center">
        <AddFieldModal onClose={() => onModalCloseRequest()} onAddField={onAddFieldRequest}></AddFieldModal>
      </Modal>
    </div>
  );
  const halfWidth = (
    <div className="flex w-full modal-parent">
      <button className="flex justify-center w-full rounded bg-purple-100 text-purple-700 my-2 mr-2 py-0.5 text-sm"
        type="button" onClick={() => setIsModalOpen(true)}>
        <Icon className="mr-1" name="add_circle"></Icon>
        <div className="ml-1">ADD FIELD</div>
      </button>
      <button className="flex justify-center w-full rounded bg-purple-100 text-purple-700 my-2 ml-2 py-0.5 text-sm"
        type="button" onClick={() => setIsModalOpen(true)}>
        <Icon className="mr-1" name="add_circle"></Icon>
        <div className="ml-1">ADD FIELD</div>
      </button>
      <Modal isOpen={isModalOpen} onCloseRequest={onModalCloseRequest} modalPos="center">
        <AddFieldModal onClose={() => onModalCloseRequest()} onAddField={onAddFieldRequest}></AddFieldModal>
      </Modal>
    </div>
  );
  if (width === "half") {
    return halfWidth;
  } else {
    return fullWidth;
  }
};