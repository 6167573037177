import axios from "axios";
import { useEffect, useState } from "react";
import { Icon } from "../../atoms/Icon/Icon";
import { InputText } from "../../atoms/InputText";
import { RequireAuth, useAuth } from "../LoginPage";
import { InputCheckbox } from "../../atoms/InputCheckbox/InputCheckbox";
import PageNav from "../../molecules/PageNav/PageNav";
import Breadcrumbs from "../../molecules/Breadcrumbs/Breadcrumbs";
import FilterList from "../../organisms/FilterList/FilterList";
import { QueryClient, QueryClientProvider, useQuery, useQueryClient } from "@tanstack/react-query";
import Modal from "../../molecules/Modal/Modal";
import Row from "../../organisms/Row/Row";
import { IconButton } from "../../atoms/IconButton";

const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';
const token = process.env.REACT_APP_RECORDS_RAILS_CUSTOM_TOKEN;

interface IFieldArrayItem {
  name: string;
  checked: boolean;
}
interface IColumnFilterList {
  fieldArray: IFieldArrayItem[];
  onClose: () => void;
  onFieldArrayChange: (field: IFieldArrayItem, index: number) => void;
}
function ColumnFilterList(props: IColumnFilterList) {
  const { fieldArray, onClose, onFieldArrayChange } = props;
  return (
    <div className="w-full rounded">
      <div className="flex justify-between text-gray-500">
        <div className="flex justify-start">
          <Icon name="filter_list"></Icon>
          <span className="ml-2">COLUMN</span>
        </div>
        <div className="flex justify-end">
          <button type="button" onClick={() => onClose()} className="">
            <Icon name="close"></Icon>
          </button>
        </div>
      </div>

      <div className="text-gray-500">
        Customize displayed columns on your table
      </div>

      <div className="flex justify-start mt-4">
        <Icon name="push_pin"></Icon>
        <span>Freezed Column</span>
      </div>

      <ul className="">
        {
          fieldArray.map((field, index) => (
            <li className="flex justify-between rounded border border-gray-300 p-2 my-2" key={index}>
              <div className="">
                <input type="checkbox" checked={field.checked} onChange={() => onFieldArrayChange(field, index)}></input>
                <span className="ml-2">{field.name}</span>
              </div>
              <button className="">
                <Icon name="unfold_more"></Icon>
              </button>
            </li>
          ))
        }           
      </ul>

      <div className="flex justify-end">
        <button className="bg-purple-600 text-white px-4 py-2 rounded">
          SAVE
        </button>
      </div>

    </div>
  )
}
export default function EmployeeListPage() {
  let auth = useAuth()

  const [users, setUsers] = useState<any[]>([]);
  const [totalUserCount, setTotalUserCount] = useState<number>(0);
  const [totalRequestCount, setTotalRequestCount] = useState<number | null>(null);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  function onFilterModalCloseRequest() {
    setIsFilterModalOpen(false);
  }
  const [filtersExist, setFiltersExist] = useState(false);

  const [isColumnModalOpen, setIsColumnModalOpen] = useState(false);
  function onColumnModalCloseRequest() {
    setIsColumnModalOpen(false);
  }

  const defaultFieldArr = [
    {name: "Name", checked: true},
    {name: "Email", checked: true},
    {name: "Department", checked: true},
    {name: "Status", checked: true},
  ]
  const [fieldArray, setFieldArray] = useState<IFieldArrayItem[]>(defaultFieldArr);
  const fieldsStr = fieldArray.filter((field) => field.checked).map((field) => field.name).join(",");
  function onFieldArrayChange(field: IFieldArrayItem, index: number) {
    const newFieldArray = [...fieldArray];
    newFieldArray[index].checked = !field.checked;
    setFieldArray(newFieldArray);
  }

  const [queryValue, setQueryValue] = useState("");
  const usersApiUrl = `${RECORDS_RAILS_URL}/users`;
  const authToken = `Bearer ${token}`;

  interface IResponseData {
    users: any[];
    count: number;
  }
  const fetchUsers = (): Promise<IResponseData> => 
    axios.get(
      usersApiUrl,
      { 
        params:  { 
          fields: `${fieldsStr}`,
          Name: `${queryValue}`, 
        }, 
        headers: { Authorization: authToken } 
      }
    ).then((response) => response.data
    ).catch((err) => {
      console.log(err);
  })

  const { data, refetch, isError, isRefetching } = useQuery({
    queryKey: ['search_results', queryValue],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
    enabled: false
  })
  
  const userList = users.map((user) => (
    <Row 
      key={user.id}
      user={user}
    />
  ));

  const usersLength = users.length;
  const firstElement = 1;
  const lastElement = usersLength;

  useEffect(
    () => {
      refetch();
    }, [queryValue]
  );

  useEffect(
    () => {
      if (data !== undefined ) {
        setUsers(data.users);
        setTotalUserCount(data.count);
      }
    }, [data, isRefetching]
  );
  
  useEffect(() => {
    if (auth.user){
      const apiUrl = `${RECORDS_RAILS_URL}/users?entry_status=pending&same_application=true`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(apiUrl, { headers }).then((response) => {
        const count = response.data["count"];
        setTotalRequestCount(count);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
    }
  }, [auth.user_id]);

  const loadingTemplate = (
    <p className="text-center">Loading...</p>
  );
  const listTemplate = (
    <table className="table-fixed w-full border-b-1 border-gray-200 overflow-x-auto">
      <thead>
        <tr className="border-b-2 border-gray-200">
          <th className="my-1 font-normal text-left text-gray-500 w-12">
            <InputCheckbox></InputCheckbox>
          </th>
          <th className="my-1 font-normal text-left text-gray-500">
            <button>
              <Icon name="arrow_upward"></Icon>
            </button>
            Name
          </th>
          <th className="my-1 font-normal text-left text-gray-500">
            <button>
              <Icon name="arrow_upward"></Icon>
            </button>
            Email
          </th>
          <th className="my-1 font-normal text-left text-gray-500">
            <button>
              <Icon name="arrow_upward"></Icon>
            </button>
            Department
          </th>
          <th className="my-1 font-normal text-left text-gray-500">
            <button>
              <Icon name="arrow_upward"></Icon>
            </button>
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {userList}
      </tbody>
    </table>
  );
  return (
    <div className="h-full mr-4 mt-6">
      {/* EMPLOYEE COUNT */}
      <div className="flex-row space-x-6">
        <div className="rounded-lg p-4 w-80 inline-block" style={{backgroundColor: '#BFE6FE'}}>
          <div className="font-bold" style={{color: '#3CA6EC'}}>EMPLOYEES</div>
          <div className="text-4xl font-bold" style={{color: '#236AA3'}}>{isRefetching ? '-' : totalUserCount}</div>
        </div>
        
        <div className="rounded-lg p-4 w-80 inline-block" style={{backgroundColor: '#BFE6FE'}}>
          <div className="font-bold flex-row" style={{color: '#3CA6EC'}}>
            <span>EDIT REQUESTS</span>
            <a href="/edit-requests" className="flex-row inline-block items-center h-full float-right -mt-0.5">
              <Icon name="chevron_right" className="text-xl"></Icon>
            </a>
          </div>
          <div className="text-4xl font-bold" style={{color: '#236AA3'}}>{totalRequestCount === null ? '-' : totalRequestCount}</div>
        </div>
      </div>

      <div>
        <h3 className="text-2xl font-bold my-6 text-black">Employees</h3>
      </div>

      <div className="p-4 border border-gray-200 rounded-lg h-5/6">
        {/* SEARCH  */}
        <div className="flex items-center my-4 modal-parent">
          <div className="w-1/2 flex border border-gray-300 rounded-lg py-1 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-100">
            <Icon name="search" className="text-2xl mr-2"></Icon>
            <input onChange={(e) => setQueryValue(e.target.value)} value={queryValue}
              className="w-full"></input>
          </div>
          <button className="w-24 flex justify-evenly border border-gray-300 text-gray-500 rounded-lg py-2 mx-1 leading-tight">
            <Icon name="filter_list"></Icon>
            <span className="">FILTER</span>
          </button>
          <button type="button" onClick={() => setIsColumnModalOpen(true)}
            className="w-28 flex justify-evenly border border-gray-300 text-gray-500 rounded-lg py-2 mx-1 leading-tight">
            <Icon name="view_column"></Icon>
            <span className="">COLUMNS</span>
          </button>
          <Modal isOpen={isColumnModalOpen} onCloseRequest={onColumnModalCloseRequest} modalPos="center">
              <ColumnFilterList fieldArray={fieldArray} onClose={onColumnModalCloseRequest} onFieldArrayChange={onFieldArrayChange}></ColumnFilterList>
          </Modal>
        </div>

        {/* FILTER  */}
        {filtersExist && <FilterList></FilterList>}

        <PageNav firstElement={firstElement} lastElement={lastElement} totalCount={usersLength}></PageNav>

        {/* USER LIST */}
        <div className="my-4">
          {isRefetching ? loadingTemplate : listTemplate}
        </div>

        <PageNav firstElement={firstElement} lastElement={lastElement} totalCount={usersLength}></PageNav>

      </div>

    </div>
  );
}