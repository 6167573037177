import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo.png'
import { Icon } from '../../atoms/Icon/Icon';
import { AuthStatus, useAuth } from '../../pages/LoginPage';

export default function TopNavbar({updateToken}: {updateToken: React.Dispatch<React.SetStateAction<string>>}) {
    let auth = useAuth();
    let navigate = useNavigate();
  
    return (
        <div className="flex justify-between shadow items-center" style={{backgroundColor: "white"}}>
            <img className="m-4" src={logo} alt="Cast LMS Logo"></img>
            <AuthStatus></AuthStatus>
            {
                auth.user ? 
                <button className='text-3xl m-4 text-center'
                    onClick={() => {
                        auth.signout(() => {
                            navigate("/");
                            localStorage.setItem('hris_buripass_user_info', ""); //changed
                        });
                        updateToken("");
                }}>
                    <Icon name='exit_to_app' style={{color: '#7A3CF1'}}></Icon>
                </button>
                :
                ""
            }
        </div>
    );
} 