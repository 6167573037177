import { useState } from "react";
import Modal from "../Modal/Modal";

export default function DeleteModal(props: any) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onModalCloseRequest() {
    setIsModalOpen(false);
  }
  function onDeleteRequest() {
    setIsModalOpen(false);
    props.deleteItem();
  }

  return (
        <div className='w-full text-sm font-medium text-gray-700'>
            <button type="button" onClick={() => setIsModalOpen(true)} 
                className='w-fit text-left'>Delete</button>
            <Modal isOpen={isModalOpen} onCloseRequest={onModalCloseRequest} modalPos="center">
                <div className='p-4'>
                    <p>Are you sure you want to delete this item?</p>
                    <div className='flex mt-4'>
                        <button onClick={onModalCloseRequest} 
                            className='bg-gray-200 text-gray-700 px-4 py-2 rounded-md mr-2'>Cancel</button>
                        <button onClick={onDeleteRequest}
                            className='bg-red-600 text-white px-4 py-2 rounded-md'>Delete</button>
                    </div>
                </div>
            </Modal>
        </div>
  )
}