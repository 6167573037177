import React from "react";
import { InputTextProps } from "../InputText";

export interface InputCheckboxProps extends InputTextProps {}

export function InputCheckbox(props: InputCheckboxProps) {
  return (
    <input
      {...props}
      type="checkbox"
      className="rounded h-4 w-4 text-primary-500 hover:text-primary-600 focus:ring-primary-600"
    />
  );
}
