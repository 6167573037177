import { RequireAuth, useAuth } from "../LoginPage";
import axios from "axios";
import { SyntheticEvent, useEffect, useState, useRef, useLayoutEffect } from "react";
import { Icon } from "../../atoms/Icon/Icon";
import { InputText } from "../../atoms/InputText";
import { InputRadio } from "../../atoms/InputRadio/InputRadio";
import { InputCheckbox } from "../../atoms/InputCheckbox/InputCheckbox";
import { Select } from "../../atoms/Select/Select";
import Datepicker from "react-tailwindcss-datepicker";
import profileBg from '../../../assets/profile-bg.png'
import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  TabsHeaderProps,
  Tab,
  TabPanel,
  Tooltip,
} from "@material-tailwind/react";
import { Label } from "../../atoms/Label/Label";
import { InputNumber } from "../../atoms/InputNumber/InputNumber";
import { Toast, ToastStatus } from "../../molecules/Toast/Toast";
import { Button } from "../../atoms/Button/Button";
import AWS, { AWSError } from 'aws-sdk';
import { PromiseResult } from "aws-sdk/lib/request";
import { PutObjectOutput } from "aws-sdk/clients/s3";
import { InputPassword } from "../../atoms/InputPassword";
import { InputTextArea } from "../../atoms/InputTextArea/InputTextArea";
import { IconButton } from "../../atoms/IconButton";
// import Datepicker from "tailwind-datepicker-react"

const BURIPASS_TOKENINFO_URL = 'https://staging.pass.buri.dev/oauth/token/info';
const BURIPASS_USERINFO_URL = 'https://staging.pass.buri.dev/oauth/userinfo';
const BURIPASS_USERS_URL = 'https://staging.pass.buri.dev/users';
const BURIPASS_CONFIRMATION_URL = 'https://staging.pass.buri.dev/users/confirmation';
const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';

function isEmpty(field: any, answer: any) {
  const text_input = ["short_text", "paragraph", "url", "email", "time", "radio", "dropdown", "date", "file_upload"];

  if (text_input.includes(field.field_type) && (answer.value_str === null || answer.value_str === "")){
    return true;
  }
  else if (field.field_type == "number" && (answer.value_int === null || answer.value_int === "")){
    return true
  }
  else if (field.field_type == "checkbox" && answer.bool === null){
    return true
  }
  return false;
}

function Option(props: any) {
  const option = props.option;
  
  return (
    <option value={option.name}>{option.name}</option>
  )
};

function Field(props: any) {
  let auth = useAuth();

  const text_input = ["short_text", "email", "time"];
  const extra_str_input = ["radio", "dropdown", "date", "file_upload", "paragraph"];
  // const datetime_input = ["date", "time"];
  // , "dropdown", "checkbox", "radio", "number"];
  const field = props.field;
  const can_employee_edit = field.can_employee_update;
  const entry_list = props.entry_list;
  const submission = props.submission;
  const entry_list_len = entry_list?.length;
  const width = field.field_width == "half" ? "col-span-1" : "col-span-2";

  let read_only = false;

  if (!props.isAdmin && !can_employee_edit){
    read_only = true;
  }
  
  const [file, setFile] = useState<File>();
  const [choices, setChoices] = useState<any[]>([]);
  const [answer, setAnswer] = useState<any>({
    value_str: "",
    value_int: "",
    value_bool: false,
    value_file_url: "",
    approval_status: "pending",
    reject_message: ""
  });
  const [value, setValue] = useState<any>({
    startDate: "",
    endDate: ""
  });

  const uploadFile = async () => {
    if (file !== undefined && file !== null){
      var name_arr = file.name.split(".");
      const final_name_arr = name_arr.map((element, index) => {
        if (index==name_arr.length - 2){
          return element.concat("-", Date.now().toString());
        }
        else{
          return element;
        }
      });
      const final_name = final_name_arr.join(".");
      const S3_BUCKET = "hris-records-uploads/file-submissions";
      const REGION = "ap-northeast-1";

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_RECORDS_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_RECORDS_AWS_SECRET_KEY,
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: final_name,
        Body: file,
        ContentDisposition: "attachment;filename=\"".concat(file.name,"\"")
      };

      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log(
            "Uploading " + ((evt.loaded * 100) / evt.total).toString() + "%"
          );
        })
        .promise();

      await upload.then((value: PromiseResult<PutObjectOutput, AWSError>) => {
        console.log(value.$response.error);
      });

      const file_url = `https://hris-records-uploads.s3.ap-northeast-1.amazonaws.com/file-submissions/${final_name}`

      const newAnswer = JSON.parse(JSON.stringify(answer));
      newAnswer.value_str = file.name;
      newAnswer.value_file_url = file_url
      newAnswer.value_bool = null;
      newAnswer.value_int = null;
      setAnswer(newAnswer);
      return newAnswer;
    }
    else {
      return answer;
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files !== null){
      setFile(files[0]);
      const newAnswer = JSON.parse(JSON.stringify(answer));
      newAnswer.value_str = files[0].name;
      newAnswer.value_file_url = null;
      newAnswer.value_bool = null;
      newAnswer.value_int = null;
      setAnswer(newAnswer);
      props.setAnswered((prev: any) => {
        return {
          ...prev,
          [field.id]: true
        }
      });
    }
    else {
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: false
          }
        });
    }
  };

  const handleValueChange = (newValue: React.SetStateAction<any>) => {
    if (newValue.startDate === null || newValue.endDate === null){
      newValue = {
        "startDate": "",
        "endDate": "",
      };
    }
    setValue(newValue);
    const newAnswer = JSON.parse(JSON.stringify(answer));
    newAnswer.value_str = newValue.startDate;
    newAnswer.value_bool = null;
    newAnswer.value_int = null;
    setAnswer(newAnswer);
    if ((newAnswer.value_str !== null && newAnswer.value_str !== "" && field.is_required) || !field.is_required){
      
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: true
          }
        });
    }
    else {
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: false
          }
        });
    }
  };

  const handleTextChange = (e: SyntheticEvent) => {
    const newAnswer = JSON.parse(JSON.stringify(answer));
    newAnswer.value_str = (e.target as HTMLInputElement).value;
    newAnswer.value_bool = null;
    newAnswer.value_int = null;
    setAnswer(newAnswer);
    if ((newAnswer.value_str !== null && newAnswer.value_str !== "" && field.is_required) || !field.is_required){
      
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: true
          }
        });
    }
    else {
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: false
          }
        });
    }
  }

  const handleNumChange = (e: SyntheticEvent) => {
    const newAnswer = JSON.parse(JSON.stringify(answer));
    newAnswer.value_str = null;
    newAnswer.value_bool = null;
    newAnswer.value_int = (e.target as HTMLInputElement).value;
    setAnswer(newAnswer);
    if ((newAnswer.value_num !== null && newAnswer.value_int !== "" && field.is_required) || !field.is_required){
      
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: true
          }
        });
    }
    else {
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: false
          }
        });
    }
  }

  const handleBoolChange = (e: SyntheticEvent) => {
    const newAnswer = JSON.parse(JSON.stringify(answer));
    newAnswer.value_bool = !answer.value_bool;
    newAnswer.value_str = null;
    newAnswer.value_int = null;
    setAnswer(newAnswer);
    if ((newAnswer.value_bool !== null && field.is_required) || !field.is_required){
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: true
          }
        });
    }
    else {
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: false
          }
        });
      }
  }

  const choices_len = choices.length;

  useEffect(() => {
    const apiUrl = `${RECORDS_RAILS_URL}/fields/${field.id}/field_choices`;
    const headers = {
      'Authorization': `Bearer ${auth.access_token}`,
    };
    axios.get(apiUrl, { headers }).then((response) => {
      const field_choices = response.data["field_choices"];
      setChoices(field_choices);
    }).catch(function(error) {console.log(error)});
  }, [choices_len, field]);
  
  useEffect(() => {
    if (entry_list != null && entry_list.length){
      let final_entry = entry_list[0];
      if ((text_input.includes(field.field_type) || extra_str_input.includes(field.field_type)) && final_entry.value_str === null){
        final_entry.value_str = "";
      }
      else if (field.field_type == "number" && final_entry.value_int == null){
        final_entry.value_int = "";
      }
      else if (field.field_type == "file_upload" && final_entry.value_file_url == null){
        final_entry.value_file_url = "";
      }
      else if (field.field_type == "checkbox" && final_entry.value_bool == null){
        final_entry.value_bool = false;
      }
      if (!field.is_required || (!isEmpty(field, final_entry) && field.is_required)) {
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: true
          }
        });
      }
      else {
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: false
          }
        });
      }
      setAnswer(final_entry);
      if (field.field_type == "date"){
        const default_date = {
          startDate: final_entry.value_str,
          endDate: final_entry.value_str
        };
        setValue(default_date);
      }
    }
    else if (!field.is_required || field.field_type == "checkbox"){
        props.setAnswered((prev: any) => {
          return {
            ...prev,
            [field.id]: true
          }
        });
    }
    else {
      props.setAnswered((prev: any) => {
        return {
          ...prev,
          [field.id]: false
        }
      });
    }
  }, [entry_list_len]);

  useLayoutEffect(() => {
    if (props.newSubmission) {
      const apiUrl = `${RECORDS_RAILS_URL}/form_submissions/${props.newSubmission}/field_entries`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      uploadFile().then((ans) => {
        let ans_str = text_input.includes(field.field_type) || extra_str_input.includes(field.field_type) ? ans.value_str : null;
        if (ans_str === ""){
          ans_str = null;
        }
        let ans_num = field.field_type == "number" ? ans.value_int : null;
        if (ans_num === ""){
          ans_num = null;
        }
        let ans_file = field.field_type == "file_upload" ? ans.value_file_url : null;
        if (ans_file === ""){
          ans_file = null;
        }
        const payload = {
          'value_str': ans_str,
          'value_int': ans_num,
          'value_bool': field.field_type == "checkbox" ? ans.value_bool : null,
          'value_file_url': ans_file,
          'field_id': field.id
        }
        axios.post(apiUrl, payload, { headers }).then((response) => {
          const entry_data = response.data;
        }).catch(function(error) {
          props.setError(true);
          let e = error;
          if (error.response) {
            e = error.response.data;                   // data, status, headers
            if (error.response.data && error.response.data.error) {
              e = error.response.data.error;           // my app specific keys override
            }
          } else if (error.message) {
            e = error.message;
          } else {
            e = "Unknown error occured";
          }
          console.log(e);
        });
        props.setEntryTrigger((trigger: number) => trigger + 1);
      }
      );
    }
  }, [props.newSubmission]);
  var option_list;
  
  if (field.field_type == "dropdown"){
    option_list = choices.map((choice) => (
      <Option 
        key={choice.id}
        option={choice}
      />
    ));
  }
  else if (field.field_type == "radio"){
    option_list = choices.map((choice) => (
      <div key={"div-".concat(choice.id)}>
        <Label key={"label-".concat(choice.id)} htmlFor={choice.id} className="text-gray-500 flex items-center">
          <InputRadio 
            id={choice.id}
            key={choice.id}
            name={field.name}
            value={choice.name}
            checked={choice.name === answer.value_str}
            onChange={handleTextChange}
            style={{marginRight: "6px", accentColor: "#6C2BDD"}}
            disabled={read_only}
          />
          {choice.name} {choice.name == answer.value_str}
        </Label>
      </div>
    ));
  }
  
  var field_elements = (
    <div></div>
    );
  
  let input_style:any = {
    color: "black"
  }

  // if (read_only){
  //   input_style.backgroundColor = "#E5E5E5";
  //   input_style.borderColor = "#A3A3A3";
  // }

  if (text_input.includes(field.field_type)){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements = (
      <div className={width}>
        <Label htmlFor={field.id} className="text-gray-500">
          <div className="flex items-center">
            {
              field.field_hint !== null && field.field_hint !== "" ?
              (
                <Tooltip content={field.field_hint} placement="top-start" className="max-w-[67%] text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10">
                  <div className="flex items-center h-full">
                  <Icon
                  name="info"
                  className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
                </Tooltip>
              ) :
              ""
            }
            {field.display_name}
            {
              field.is_required ? 
              "*" :
              ""
            }
            {
              field.needs_approval && answer.approval_status != "approved" ?
              (answer.approval_status == "rejected" ?
              
              <Tooltip
                content={final_message}
                placement="top"
                className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
                <div className="flex items-center h-full">
                <Icon
                name="error"
                className="ml-1" style={{color: "#E54240"}}></Icon></div>
              </Tooltip> : 
              <Icon
              name="error"
              style={{color: "#4B83F9"}}
              className="ml-1"></Icon>
              ) :
              ""
            }
          </div>
          {(submission != null && entry_list != null && answer.value_str !== null) || submission == null ?
            <InputText id={field.id} value={answer.value_str} onChange={handleTextChange} style={input_style} disabled={read_only}></InputText> :
            <p>loading...</p>
          }
        </Label>
      </div>
    );
  }
  if (field.field_type == "paragraph"){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements = (
      <div className={width}>
        <Label htmlFor={field.id} className="text-gray-500">
          <div className="flex items-center">
            {
              field.field_hint !== null && field.field_hint !== "" ?
              (
                <Tooltip content={field.field_hint} placement="top-start" className="max-w-[67%] text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10">
                  <div className="flex items-center h-full">
                  <Icon
                  name="info"
                  className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
                </Tooltip>
              ) :
              ""
            }
            {field.display_name}
            {
              field.is_required ? 
              "*" :
              ""
            }
            {
              field.needs_approval && answer.approval_status != "approved" ?
              (answer.approval_status == "rejected" ?
              
              <Tooltip
                content={final_message}
                placement="top"
                className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
                <div className="flex items-center h-full">
                <Icon
                name="error"
                className="ml-1" style={{color: "#E54240"}}></Icon></div>
              </Tooltip> : 
              <Icon
              name="error"
              style={{color: "#4B83F9"}}
              className="ml-1"></Icon>
              ) :
              ""
            }
          </div>
          {(submission != null && entry_list != null && answer.value_str !== null) || submission == null ?
            <InputTextArea id={field.id} value={answer.value_str} onChange={handleTextChange} style={input_style} disabled={read_only}></InputTextArea> :
            <p>loading...</p>
          }
        </Label>
      </div>
    );
  }
  else if (field.field_type == "number"){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements = (
      <div className={width}>
        <Label htmlFor={field.id} className="text-gray-500">
          <div className="flex items-center">
            {
              field.field_hint !== null && field.field_hint !== "" ?
              (
                <Tooltip content={field.field_hint} placement="top-start" className="max-w-[67%] text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10">
                  <div className="flex items-center h-full">
                  <Icon
                  name="info"
                  className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
                </Tooltip>
              ) :
              ""
            }
            {field.display_name}
            {
              field.is_required ? 
              "*" :
              ""
            }
            {
              field.needs_approval && answer.approval_status != "approved" ?
              (answer.approval_status == "rejected" ?
              
              <Tooltip
                content={final_message}
                placement="top"
                className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
                <div className="flex items-center h-full">
                <Icon
                name="error"
                className="ml-1" style={{color: "#E54240"}}></Icon></div>
              </Tooltip> : 
              <Icon
              name="error"
              style={{color: "#4B83F9"}}
              className="ml-1"></Icon>
              ) :
              ""
            }
          </div>
          {(submission != null && entry_list != null && answer.value_int !== null) || submission == null ?
            <InputNumber id={field.id} value={answer.value_int} onChange={handleNumChange} style={input_style} disabled={read_only}></InputNumber> :
            <p>loading...</p>
          }
        </Label>
      </div>
    );
  }
  else if (field.field_type == "date"){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements = (
      <div className={width}>
        <Label htmlFor={field.id} className="text-gray-500">
          <div className="flex items-center">
            {
              field.field_hint !== null && field.field_hint !== "" ?
              (
                <Tooltip content={field.field_hint} placement="top-start" className="max-w-[67%] text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10">
                  <div className="flex items-center h-full">
                  <Icon
                  name="info"
                  className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
                </Tooltip>
              ) :
              ""
            }
            {field.display_name}
            {
              field.is_required ? 
              "*" :
              ""
            }
            {
              field.needs_approval && answer.approval_status != "approved" ?
              (answer.approval_status == "rejected" ?
              
              <Tooltip
                content={final_message}
                placement="top"
                className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
                <div className="flex items-center h-full">
                <Icon
                name="error"
                className="ml-1" style={{color: "#E54240"}}></Icon></div>
              </Tooltip> : 
              <Icon
              name="error"
              style={{color: "#4B83F9"}}
              className="ml-1"></Icon>
              ) :
              ""
            }
          </div>
          {(submission != null && entry_list != null && answer.value_str !== null) || submission == null ?
          <Datepicker
          inputId={field.id}
          inputClassName="w-full border border-gray-300 rounded py-2 px-3 pl-9 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-100"
          primaryColor={"purple"}
          toggleClassName="left-0 absolute h-full px-3 text-gray-600" 
          asSingle={true} 
          useRange={false} 
          value={value}
          onChange={handleValueChange}
          disabled={read_only}/>
          : <p>loading...</p>
          }
        </Label>
      </div>
    )
  }
  else if (field.field_type == "radio"){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements = 
    (
      <div className={width}>
        <div className="flex items-center text-gray-500">
          {
            field.field_hint !== null && field.field_hint !== "" ?
            (
              <Tooltip content={field.field_hint} placement="top-start" className="text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10">
                <div className="flex items-center h-full">
                <Icon
                name="info"
                className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
              </Tooltip>
            ) :
            ""
          }
          <span>{field.display_name}</span>
          {
            field.is_required ? 
            "*" :
            ""
          }
          {
            field.needs_approval && answer.approval_status != "approved" ?
            (answer.approval_status == "rejected" ?
            
            <Tooltip
              content={final_message}
              placement="top"
              className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
              <div className="flex items-center h-full">
              <Icon
              name="error"
              className="ml-1" style={{color: "#E54240"}}></Icon></div>
            </Tooltip> : 
            <Icon
            name="error"
            style={{color: "#4B83F9"}}
            className="ml-1"></Icon>
            ) :
            ""
          }
        </div>
        {(submission != null && entry_list != null && answer.value_str !== null) || submission == null ?
          option_list :
          <p>loading...</p>
        }
      </div>
    );
  }
  else if (field.field_type == "checkbox"){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements = (submission != null && entry_list != null && answer.value_bool != null) || submission == null ?
      (
        <div className={width}>
          <Label htmlFor={field.id} className="text-gray-500 flex items-center">
            <InputCheckbox 
              id={field.id}
              name={field.name}
              value={field.name}
              checked={answer.value_bool}
              onChange={handleBoolChange}
              style={{marginRight: "6px", accentColor: "#6C2BDD"}}
              disabled={read_only}
            />
            <div className="flex items-center">
              {
                field.field_hint !== null && field.field_hint !== "" ?
                (
                  <Tooltip content={field.field_hint} placement="top-start" className="max-w-[67%] text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10">
                    <div className="flex items-center h-full">
                    <Icon
                    name="info"
                    className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
                  </Tooltip>
                ) :
                ""
              }
              {field.display_name}
              {
                field.needs_approval && answer.approval_status != "approved" ?
                (answer.approval_status == "rejected" ?
                
                <Tooltip
                  content={final_message}
                  placement="top"
                  className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
                  <div className="flex items-center h-full">
                  <Icon
                  name="error"
                  className="ml-1" style={{color: "#E54240"}}></Icon></div>
                </Tooltip> : 
                <Icon
                name="error"
                style={{color: "#4B83F9"}}
                className="ml-1"></Icon>
                ) :
                ""
              }
            </div>
          </Label>
        </div>
      ) :
      <p>loading...</p>;
  }
  else if (field.field_type == "dropdown"){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements =(
      <div className={width}>
          <Label htmlFor={field.id} className="text-gray-500">
          <div className="flex items-center">
            {
              field.field_hint !== null && field.field_hint !== "" ?
              (
                <Tooltip content={field.field_hint} placement="top-start" className="max-w-[67%] text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10">
                  <div className="flex items-center h-full">
                  <Icon
                  name="info"
                  className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
                </Tooltip>
              ) :
              ""
            }
            {field.display_name}
            {
              field.is_required ? 
              "*" :
              ""
            }
            {
              field.needs_approval && answer.approval_status != "approved" ?
              (answer.approval_status == "rejected" ?
              
              <Tooltip
                content={final_message}
                placement="top"
                className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
                <div className="flex items-center h-full">
                <Icon
                name="error"
                className="ml-1" style={{color: "#E54240"}}></Icon></div>
              </Tooltip> : 
              <Icon
              name="error"
              style={{color: "#4B83F9"}}
              className="ml-1"></Icon>
              ) :
              ""
            }
          </div>
            {(submission != null && entry_list != null && answer.value_str != null) || submission == null ?
            <Select
            name={field.name}
            id={field.id}
            onChange={handleTextChange}
            value={answer.value_str}
            className="text-black rounded px-3 py-2 gray-600"
            style={{
              appearance: "none",
              backgroundImage: "url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='evenodd' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='m16.843 10.211c.108-.141.157-.3.157-.456 0-.389-.306-.755-.749-.755h-8.501c-.445 0-.75.367-.75.755 0 .157.05.316.159.457 1.203 1.554 3.252 4.199 4.258 5.498.142.184.36.29.592.29.23 0 .449-.107.591-.291 1.002-1.299 3.044-3.945 4.243-5.498z'/%3e%3c/svg%3E\")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 0.75rem center",
              backgroundSize: "0.8rem",
            }}
            disabled={read_only}>
              {option_list}
            </Select>
            :
            <p>loading...</p>
            }
          </Label>
        </div>
      );
  }
  else if (field.field_type == "file_upload"){
    const final_message = (
      <div className="flex-col text-black not-italic">
        <strong>Reason for Rejecting Edit</strong>
        <div className="text-gray-500 italic mt-1">
          {answer.reject_message !== null && answer.reject_message !== "" ? answer.reject_message : "N/A"}
        </div>
      </div>
    );
    field_elements =(
        <div className={`flex-col ${width}`}>
          <span className="text-gray-500 block mb-1">
          <div className="flex items-center">
            {
              field.field_hint !== null && field.field_hint !== "" ?
              (
                <Tooltip content={field.field_hint} placement="top-start" className="text-gray-500 italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 max-w-[67%]">
                  <div className="flex items-center h-full">
                  <Icon
                  name="info"
                  className="mr-1.5 material-icons-outlined text-sm"></Icon></div>
                </Tooltip>
              ) :
              ""
            }
            {field.display_name}
            {
              field.is_required ? 
              "*" :
              ""
            }
            {
              field.needs_approval && answer.approval_status != "approved" ?
              (answer.approval_status == "rejected" ?
              
              <Tooltip
                content={final_message}
                placement="top"
                className={`w-64 ${answer.reject_message !== null && answer.reject_message !== "" ? "text-gray-500" : "text-gray-400"} italic border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10`}>
                <div className="flex items-center h-full">
                <Icon
                name="error"
                className="ml-1" style={{color: "#E54240"}}></Icon></div>
              </Tooltip> : 
              <Icon
              name="error"
              style={{color: "#4B83F9"}}
              className="ml-1"></Icon>
              ) :
              ""
            }
          </div>
          </span>
          <div className="flex-row">
            <div className="rounded border p-1 cursor-pointer border-gray-600 bg-gray-100 w-fit inline mr-3">
              <Label htmlFor={field.id} className="cursor-pointer">
                Select file
              </Label>
            </div>
            {(submission != null && entry_list != null && answer.value_str !== null) || submission == null ?
              (
                <div className="inline">
                  <input id={field.id} type="file" onChange={handleFileChange} hidden/>
                  {answer.value_str === "" ? "No file selected" : answer.value_str}
                </div>
              ) :
              <p>loading...</p>
            }
          </div>
        </div>
    );
  }
  return field_elements;
}

function FieldGroup(props: any) {
  let auth = useAuth();
  const group = props.group;
  
  const [submission, setSubmission] = useState<string | null>("");
  const [newSubmission, setNewSubmission] = useState<string>("");
  const [fields, setFields] = useState<any[]>([]);
  const [entries, setEntries] = useState<any[] | null>([]);
  const [entryTrigger, setEntryTrigger] = useState(0);
  const [answered, setAnswered] = useState<any>({});

  useEffect(() => {
    if (entryTrigger % fields.length == 0) {
      props.setGroupTrigger((trigger: number) => trigger + 1);
    }
  }, [entryTrigger]);

  useEffect(() => {
    var values = Object.keys(answered).map(function (key) { return answered[key]; });
    if (values.length == fields.length){
      if (!values.includes(false)) {
        props.setGroupAnswers((prev: any) => {
          return {
            ...prev,
            [group.id]: true
          }
        });
      }
      else {
        props.setGroupAnswers((prev: any) => {
          return {
            ...prev,
            [group.id]: false
          }
        });
      }
    }
  }, [answered]);
  
  useEffect(() => {
    if (props.trigger) {
      var values = Object.keys(props.group_answers).map(function (key) { return props.group_answers[key]; });
      if (!values.includes(false)){
        const apiUrl = `${RECORDS_RAILS_URL}/users/${auth.user_id}/form_submissions`;
        const headers = {
          'Authorization': `Bearer ${auth.access_token}`,
        };
        const payload = {
          'field_group_id': group.id,
        };
        axios.post(apiUrl, payload, { headers }).then((response) => {
          const new_submission = response.data["id"];
          setNewSubmission(new_submission);
        }).catch(function(error) {
          console.log(error);
          props.setError(true);});
      }
      else {
        props.setGroupTrigger((trigger: number) => trigger + 1);
      }
    }
  }, [props.trigger]);
  
  const field_list = (submission != null && entries != null) || submission == null ?
    fields.map((field, i) => (
      <Field 
        key={field.id}
        field={field}
        entry_list={
          entries == null ?
          entries :
          entries.filter(
          function (entry: any) {
            return entry.field_id == field.id;
          }
        )}
        submission={submission}
        newSubmission={newSubmission}
        setError={props.setError}
        setEntryTrigger={setEntryTrigger}
        isAdmin={auth.is_admin}
        setAnswered={setAnswered}
        i={i}
      />
    )):
    <p>loading...</p>;
  
  const fields_len = fields.length;

  useEffect(() => {
    const apiUrl = `${RECORDS_RAILS_URL}/field_groups/${group.id}/fields`;
    const headers = {
      'Authorization': `Bearer ${auth.access_token}`,
    };
    axios.get(apiUrl, { headers }).then((response) => {
      const fields_data = response.data["fields"];
      setFields(fields_data);
    }).catch(function(error) {console.log(error)});
    if (submission){
      const apiUrl = `${RECORDS_RAILS_URL}/form_submissions/${submission}/field_entries`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(apiUrl, { headers }).then((response) => {
        const entry_data = response.data["field_entries"];
        setEntries(entry_data);
      }).catch(function(error) {console.log(error)});
    }
  }, [fields_len, submission]);

  useEffect(() => {
    const apiUrl = `${RECORDS_RAILS_URL}/users/${auth.user_id}/form_submissions?field_group=${group.id}`;
    const headers = {
      'Authorization': `Bearer ${auth.access_token}`,
    };
    axios.get(apiUrl, { headers }).then((response) => {
      const submission_data = response.data["form_submissions"];
      if (submission_data.length){
        setSubmission(submission_data[0].id);
      }
      else {
        setSubmission(null);
        setEntries(null);
      }
    }).catch(function(error) {console.log(error)});
  }, [submission?.length]);

  return (
      <div className="font-normal text-left text-neutral-700 w-full h-full rounded-lg border border-neutral-200 p-6" style={{backgroundColor: "white"}}>
        <span className="mb-7 text-lg inline-block mb-4"><strong>{group.name}</strong></span>
        <div className="grid grid-cols-2 items-start gap-x-4 gap-y-6">
          {field_list}
        </div>
      </div>
  );
};

function Form(props: any) {
  
  let auth = useAuth();

  const tab = props.tab;
  
  const [field_groups, setFieldGroups] = useState<any[]>([]);
  const [trigger, setTrigger] = useState(0);
  const [group_trigger, setGroupTrigger] = useState(0);
  const [error, setError] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toast, setToast] = useState(<></>);
  const [group_answers, setGroupAnswers] = useState<any>({});

  const group_list = field_groups.map((group, i) => (
    <FieldGroup 
      key={group.id}
      group={group}
      trigger={trigger}
      setGroupTrigger={setGroupTrigger}
      setError={setError}
      error={error}
      setGroupAnswers={setGroupAnswers}
      group_answers={group_answers}
      i={i}
    />
  ));

  const group_len = field_groups.length;

  
  useEffect(() => {
    var values = Object.keys(group_answers).map(function (key) { return group_answers[key]; });
    if (group_trigger % group_len == 0 && group_trigger != 0 && values.length == field_groups.length) {
      if (values.includes(false)){
        setToast( 
        <Toast
          message="Please fill up the required fields"
          toastStatus="warning"
          onClose={() => setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        setIsToastOpen(true);
      }
      else if (error){
        setToast( 
        <Toast
          message="Error submitting form"
          toastStatus="error"
          onClose={() => setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        setIsToastOpen(true);
      }
      else if (group_trigger % group_len == 0 && group_trigger != 0 && !error){
        setToast( 
        <Toast
          message="Successully submitted form"
          toastStatus="success"
          onClose={() => setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        setIsToastOpen(true);
        props.setUpdateDocuments(true);
      }
    }
  }, [group_trigger]);

  useEffect(() => {
    if (tab.id){
      const apiUrl = `${RECORDS_RAILS_URL}/tabs/${tab.id}/field_groups`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(apiUrl, { headers }).then((response) => {
        const groups_data = response.data["field_groups"];
        setFieldGroups(groups_data);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
    }
  }, [props.tab.id]);

  return (
        <div>
          <div className="flex mb-7">
            <h3 className='text-xl font-bold text-black table-cell align-middle mt-auto mb-auto'>{tab.display_name}</h3>
            {field_groups.length ? 
              <Button
                className='text-md ml-auto align-middle'
                style={{
                  padding: "0rem 1.2rem",
                  height: "2.2rem"
                }}
                onClick={() => {
                  setError(false);
                  setTrigger((trigger) => trigger + 1);
                }}>
                Save
              </Button> :
              <></>
            }
          </div>
          <div>
            {isToastOpen ? (
              toast
              ) : (
              <></>
            )}
            <div className="flex flex-col space-y-4">
              {field_groups.length ? group_list : <p>No content found</p>}
            </div>
          </div>
        </div>
      );
}

function EmployeeInfo(props: any) {
  
  let auth = useAuth();
  
  const [application_uuid, setApplicationUUID] = useState("");

  const [email, setEmail] = useState("");
  const [new_email, setNewEmail] = useState("");
  const [retype_email, setRetypeEmail] = useState("");
  const [current_email_password, setCurrentEmailPassword] = useState("");
  const [password, setPassword] = useState("samplepassword");
  
  const [new_password, setNewPassword] = useState("");
  const [retype_password, setRetypePassword] = useState("");
  const [current_password, setCurrentPassword] = useState("");

  const [tabs, setTabs] = useState<any[]>([]);
  const [activeTab, setActiveTab] = React.useState("account_details");
  const [file_trigger, setFileTrigger] = useState(0);
  const [is_email_form, changeIsEmailForm] = useState(false);
  const [is_password_form, changeIsPasswordForm] = useState(false);
  
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toast, setToast] = useState(<></>);

  const [file, setFile] = useState<File>();
  const uploadFile = async () => {
    if (file !== undefined && file !== null){
      var name_arr = file.name.split(".");
      const final_name_arr = name_arr.map((element, index) => {
        if (index==name_arr.length - 2){
          return element.concat("-", Date.now().toString());
        }
        else{
          return element;
        }
      });
      const final_name = final_name_arr.join(".");
      const S3_BUCKET = "hris-records-uploads/profile-pictures";
      const REGION = "ap-northeast-1";

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_RECORDS_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_RECORDS_AWS_SECRET_KEY,
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: final_name,
        Body: file,
      };

      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log(
            "Uploading " + ((evt.loaded * 100) / evt.total).toString() + "%"
          );
        })
        .promise();

      await upload.then((value: PromiseResult<PutObjectOutput, AWSError>) => {
        console.log(value.$response.error);
      });

      const file_url = `https://hris-records-uploads.s3.ap-northeast-1.amazonaws.com/profile-pictures/${final_name}`
      const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.access_token}` },
          body: JSON.stringify({ user: { image_url: file_url} })
      };
      fetch(`${RECORDS_RAILS_URL}/users/${auth.user_id}/`, requestOptions)
          .then(response => response.json());
      props.setImageUrl(file_url);
    }
  };
  
  const handleNewEmailChange = (e: SyntheticEvent) => {
    const new_email = (e.target as HTMLInputElement).value;
    setNewEmail(new_email);
  }

  const handleRetypeEmailChange = (e: SyntheticEvent) => {
    const new_email = (e.target as HTMLInputElement).value;
    setRetypeEmail(new_email);
  }

  const handleCurrentEmailPasswordChange = (e: SyntheticEvent) => {
    const pw = (e.target as HTMLInputElement).value;
    setCurrentEmailPassword(pw);
  }

  const handleNewPasswordChange = (e: SyntheticEvent) => {
    const pw = (e.target as HTMLInputElement).value;
    setNewPassword(pw);
  }

  const handleRetypePasswordChange = (e: SyntheticEvent) => {
    const pw = (e.target as HTMLInputElement).value;
    setRetypePassword(pw);
  }

  const handleCurrentPasswordChange = (e: SyntheticEvent) => {
    const pw = (e.target as HTMLInputElement).value;
    setCurrentPassword(pw);
  }

  useEffect(() => {
    if (file_trigger){
      uploadFile();
    }
  }, [file_trigger]);
  
  function changeEmail() {
    if (auth.user){
      const headers = {
        Authorization: `Bearer ${auth.access_token}`,
        "Content-Type": 'application/json',
        Accept: 'application/json'
      };
      const payload = {
        "user": {
          "current_password": current_email_password,
          "email": new_email
        }
      };
      axios.put(BURIPASS_USERS_URL, payload, { headers }).then((response) => {
        const apiUrl = `${BURIPASS_USERS_URL}/${auth.user_id}`
        axios.get(apiUrl, { headers }).then((response) => {
          const userData = response.data;
          const unconfirmedEmail = userData["unconfirmed_email"];
          const confirmationPayload = {
            "user": {
              "email": unconfirmedEmail
            }
          };
          
          axios.post(BURIPASS_CONFIRMATION_URL, confirmationPayload, { headers }).then((response) => {
            setToast( 
            <Toast
              message="A confirmation email has been sent. Please follow the instructions written in the email."
              toastStatus="success"
              onClose={() => setIsToastOpen(false)}
              closeDelay={3000}
            ></Toast>);
            setIsToastOpen(true);
          }).catch(function(error) {
            let e = error;
            if (error.response) {
              e = error.response.data;                   // data, status, headers
              if (error.response.data && error.response.data.error) {
                e = error.response.data.error;           // my app specific keys override
              }
            } else if (error.message) {
              e = error.message;
            } else {
              e = "Unknown error occured";
            }
            console.log(`Error: ${e}`);
          });
        }).catch(function(error) {
          let e = error;
          if (error.response) {
            e = error.response.data;                   // data, status, headers
            if (error.response.data && error.response.data.error) {
              e = error.response.data.error;           // my app specific keys override
            }
          } else if (error.message) {
            e = error.message;
          } else {
            e = "Unknown error occured";
          }
          console.log(`Error: ${e}`);
        });
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            const errorDetails = e.error_details;
            let errorMessageList = [];
            if (errorDetails.email){
              errorMessageList.push(`Email ${errorDetails["email"][0]}.`);
            }
            if (errorDetails.current_password){
              errorMessageList.push(`Current password ${errorDetails["current_password"][0]}.`);
            }
            const finalMessage = errorMessageList.join(" ");
            setToast( 
            <Toast
              message={finalMessage}
              toastStatus="error"
              onClose={() => setIsToastOpen(false)}
              closeDelay={3000}
            ></Toast>);
            setIsToastOpen(true);
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(`Error: ${e}`);
      });
    }
  }
  
  function changePassword() {
    if (auth.user){
      const headers = {
        Authorization: `Bearer ${auth.access_token}`,
        "Content-Type": 'application/json',
        Accept: 'application/json'
      };
      const payload = {
        "user": {
          "current_password": current_password,
          "password": new_password
        }
      };
      axios.put(BURIPASS_USERS_URL, payload, { headers }).then((response) => {
        setToast( 
        <Toast
          message="Password successfully updated."
          toastStatus="success"
          onClose={() => setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        setIsToastOpen(true);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            const errorDetails = e.error_details;
            let errorMessageList = [];
            if (errorDetails.password){
              errorMessageList.push(`New password ${errorDetails["password"][0]}.`);
            }
            if (errorDetails.current_password){
              errorMessageList.push(`Current password ${errorDetails["current_password"][0]}.`);
            }
            const finalMessage = errorMessageList.join(" ");
            setToast( 
            <Toast
              message={finalMessage}
              toastStatus="error"
              onClose={() => setIsToastOpen(false)}
              closeDelay={3000}
            ></Toast>);
            setIsToastOpen(true);
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(`Error: ${e}`);
      });
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files !== null){
      setFile(files[0]);
      setFileTrigger((trigger) => trigger + 1);
    }
  };

  const email_readonly = (
    <div>
      <Label className="text-gray-500">
        Email
        <InputText value={email} style={{color: "black"}} disabled></InputText>
      </Label>
      <Button className="mt-0.5" onClick={() => changeIsEmailForm(true)} style={{textAlign: "left", padding: "0", background: "transparent", color: "#7A3CF1"}}>
        Change Email
      </Button>
    </div>
  )
  const email_form = (
    <div>
      <div className="space-y-2">
        <Label className="text-gray-500 inline-block w-full">
          New Email
          <InputText value={new_email} onChange={handleNewEmailChange} style={{color: "black"}}></InputText>
        </Label>
        <Label className="text-gray-500 inline-block w-full">
          Retype New Email
          <InputText value={retype_email} onChange={handleRetypeEmailChange} style={{color: "black"}}></InputText>
        </Label>
        <Label className="text-gray-500 inline-block w-full">
          Current Password*
          <InputPassword value={current_email_password} onChange={handleCurrentEmailPasswordChange} style={{color: "black"}}></InputPassword>
        </Label>
      </div>
      <div className="mt-4 flex-row justify-between w-full">
        <Button className="float-left" disabled={new_email == retype_email && new_email !== "" && retype_email !== ""? false : true} onClick={changeEmail}>
          Save Changes
        </Button>
        <Button className="float-right" color="error" onClick={() => changeIsEmailForm(false)}>
          Cancel
        </Button>
      </div>
    </div>

  )

  const password_readonly = (
    <div>
      <Label className="text-gray-500">
        Password
        <InputPassword value={password} style={{color: "black"}} disabled></InputPassword>
      </Label>
      <Button className="mt-0.5" onClick={() => changeIsPasswordForm(true)} style={{textAlign: "left", padding: "0", background: "transparent", color: "#7A3CF1"}}>
        Change Password
      </Button>
    </div>
  )

  const password_form = (
    <div>
      <div className="space-y-2">
        <Label className="text-gray-500 inline-block w-full">
          New Password
          <InputPassword value={new_password} onChange={handleNewPasswordChange} style={{color: "black"}}></InputPassword>
        </Label>
        <Label className="text-gray-500 inline-block w-full">
          Retype New Password
          <InputPassword value={retype_password} onChange={handleRetypePasswordChange} style={{color: "black"}}></InputPassword>
        </Label>
        <Label className="text-gray-500 inline-block w-full">
          Current Password*
          <InputPassword value={current_password} onChange={handleCurrentPasswordChange} style={{color: "black"}}></InputPassword>
        </Label>
      </div>
      <div className="mt-4 flex-row justify-between w-full">
        <Button className="float-left" disabled={new_password == retype_password && new_password !== "" && retype_password !== "" ? false : true} onClick={changePassword}>
          Save Changes
        </Button>
        <Button className="float-right" color="error" onClick={() => changeIsPasswordForm(false)}>
          Cancel
        </Button>
      </div>
    </div>

  )

  useEffect(() => {
    if (auth.access_token){
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(BURIPASS_USERINFO_URL, { headers }).then((response) => {
        const pass_data = response.data;
        setEmail(pass_data.email);
      }).catch(function(error) {console.log(error)});
    }
  }, [application_uuid]);
  
  useEffect(() => {
    if (auth.access_token){
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(BURIPASS_TOKENINFO_URL, { headers }).then((response) => {
        const pass_data = response.data;
        setApplicationUUID(pass_data.application.uuid);
      }).catch(function(error) {console.log(error)});
    }
  }, [application_uuid]);

  useEffect(() => {
    if (application_uuid){
      const apiUrl = `${RECORDS_RAILS_URL}/applications/${application_uuid}/tabs?parent=employee_information`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(apiUrl, { headers }).then((response) => {
        const tabs_data = response.data["tabs"];
        setTabs(tabs_data);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
    }
  }, [application_uuid]);

  var data = [
    {
      label: "Account Details",
      value: "account_details",
      page: (
        <div className="mt-[0.2rem]">
          {isToastOpen ? (
            toast
            ) : (
            <></>
          )}
          <h3 className='text-xl font-bold text-black table-cell align-middle mt-auto mb-auto'>Account Details</h3>
          <div className="mt-8 font-normal text-left text-neutral-700 w-full h-full rounded-lg border border-neutral-200 p-6" style={{backgroundColor: "white"}}>
            <div className="w-[15%] p-0 align-middle flex truncate justify-center"
              style={{borderRadius: "50%", aspectRatio: "1 / 1"}}>
              <img src={props.imageUrl}></img>
            </div>
            <Label htmlFor="file_input" className="relative w-[15%] block cursor-pointer">
            <input id="file_input" type="file" onChange={handleFileChange} hidden/>
            <div className= "w-[23%] rounded-md absolute bottom-0 right-0 bg-primary-500 hover:bg-primary-600 focus:bg-primary-600" style={{aspectRatio: "1 / 1"}}>
              <Icon name="edit"
                style={{color: "white", 
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"}}></Icon>
              </div>
            {/* <button onClick={uploadFile}>submit</button> */}
            </Label>
            <div className="grid grid-cols-2 items-start gap-x-4 gap-y-6 mt-5">
              {is_email_form ? email_form : email_readonly}
              {is_password_form ? password_form : password_readonly}
            </div>
          </div>
        </div>),
    }
  ];

  const tab_data = tabs.map((tab) => ({
      label: tab.display_name,
      value: tab.name,
      page: (<Form key={tab.id} tab={tab} setUpdateDocuments={props.setUpdateDocuments}/>
      )
    })
  );

  data = data.concat(tab_data);
 
  return (
    <div>
    <Tabs value="account_details" orientation="vertical">
      <TabsHeader
        className="bg-transparent w-64 text-start justify-start space-y-2 p-0"
        indicatorProps={{
          style:{display: "none"},
        }}
      >
        {data.map(({ label, value }) => (
          <Tab key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            style={activeTab === value ? {color: "#4B1F97", backgroundColor: "#DDD6FF"} : {color: "#4B1F97"}}
            className={activeTab === value ? "rounded-lg bg-transparent justify-start p-2": "justify-start p-2"}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, page }) => (
          <TabPanel key={value} value={value} className="py-1">
            {page}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs></div>
  );
}

function Document(props: any) {

  const entry = props.entry

  var name_arr = entry.value_str.split(".")
  name_arr.pop()

  const final_name = name_arr.join(".")
  const created = new Date(entry.created_at)
  const string_date = created.toLocaleString('default', { month: 'long', day: 'numeric', year:"numeric" });
 
  return (
    <div className="font-normal text-left w-full h-full rounded-lg border border-neutral-200 p-4 flex" style={{backgroundColor: "white"}}>
      <div className="w-fit inline-block">
        <div className="font-bold text-xl text-black">
          <Icon name="insert_drive_file" className="text-gray-600 bg-transparent text-2xl"></Icon>
          <span className="inline-block align-middle">{final_name}</span>
        </div>
        <span className="ml-1">{string_date}</span>
      </div>
      <div className="text-primary-500 hover:text-primary-700 w-fit inline-block ml-auto">
        <a href={entry.value_file_url} className="flex items-center h-full" download={entry.value_str}>
          <Icon name="download" className="text-2xl"></Icon>
          Download
        </a>
      </div>
    </div>
  );
}

function DocumentsPage(props: any) {
  
  let auth = useAuth();
  
  const [entries, setEntries] = useState<any[]>([]);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    if (auth.user_id && props.updateDocuments){
      let apiUrl = `${RECORDS_RAILS_URL}/field_entries/?field_type=file_upload&user_id=${auth.user_id}`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      if (query !== "" && query !== null){
        apiUrl = apiUrl.concat("&q=", query);
      }
      axios.get(apiUrl, { headers }).then((response) => {
        const field_entries = response.data["field_entries"];
        setEntries(field_entries);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
      props.setUpdateDocuments(false);
    }
  }, [auth.user_id, props.updateDocuments, query]);

  const all_entries = entries.map((entry) => (
    <Document
      key={"file".concat(entry.id)}
      entry={entry}>
    </Document>
  )
  );
  
  const handleQueryChange = (e: SyntheticEvent) => {
    const new_query = (e.target as HTMLInputElement).value;
    setQuery(new_query);
    props.setUpdateDocuments(true);
  }

 
  return (
    <div>
      <div className="py-2 px-3 border border-gray-300 rounded flex items-center bg-white text-gray-700 w-3/5 print:hidden">
        <Icon name="search" className="text-2xl mr-2 text-gray-500 material-icon select-none"></Icon>
        <input value={query} onChange={handleQueryChange}
          className="w-full text-md leading-tight appearance-none outline-none border-none"
          placeholder="Separate keywords with (,)"></input>
      </div>
      
      <div className="mt-8 font-normal text-left text-neutral-700 w-full h-full rounded-lg border border-neutral-200 p-6 space-y-4" style={{backgroundColor: "white"}}>
        {all_entries}
      </div>
    </div>
  );
}

function ProfilePage() {

  let auth = useAuth();

  const [activeTab, setActiveTab] = React.useState("employee_info");
  const [imageUrl, setImageUrl] = React.useState("");
  const [updateDocuments, setUpdateDocuments] = React.useState(true);
  const data = [
    {
      label: "Employee Information",
      value: "employee_info",
      page: <EmployeeInfo imageUrl={imageUrl} setImageUrl={setImageUrl} setUpdateDocuments={setUpdateDocuments}></EmployeeInfo>,
    },
    {
      label: "Documents",
      value: "documents",
      page: <DocumentsPage updateDocuments={updateDocuments} setUpdateDocuments={setUpdateDocuments}></DocumentsPage>
    },
    {
      label: "Digital Forms",
      value: "forms",
      page: <div></div>,
    },
    {
      label: "Trainings Attended",
      value: "trainings",
      page: <div></div>,
    },
  ];
  
  useEffect(() => {
    if (auth.user){
      const apiUrl = `${RECORDS_RAILS_URL}/users/${auth.user_id}`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(apiUrl, { headers }).then((response) => {
        const initial_image_url = response.data["image_url"];
        setImageUrl(initial_image_url);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
    }
  }, [auth.user_id]);

  return (
    <RequireAuth>
      <div>
        <div className="mr-4 relative mb-7">
          <img src={profileBg} className="w-full"></img>
          <div className="absolute h-full w-full top-0 bottom-0 p-4 px-6">
            <div className="w-full flex items-center mt-auto mb-auto relative" style={{top: "50%", transform: "translate(0, -50%)"}}>
              <div className="w-[9.5%] mr-4 p-0 align-middle flex truncate justify-center"
                style={{borderRadius: "50%", aspectRatio: "1 / 1"}}>
                <img src={imageUrl}></img>
              </div>
              <h2 className='text-2xl font-bold my-auto inline text-black'>{auth.user}</h2>
            </div>
          </div>
        </div>
        <div>
          
          <Tabs value={activeTab}>
            <TabsHeader
              className="rounded-none border-b-4 border-blue-gray-50 bg-transparent p-0 text-neutral-500 flex justify-start mr-4"
              indicatorProps={{
                style: {display: "none"}
              }}
            >
              {data.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  style={activeTab === value ? {color:"#4B1F97", borderColor: "#4B1F97"} : {}}
                  className={activeTab === value ? "font-bold rounded-t-lg bg-white w-auto border-b-4 px-2 -mb-1": "font-bold w-auto px-2 -mb-1"}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {data.map(({ value, page }) => (
                <TabPanel key={value} value={value} className="px-0">
                  {page}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </div>
    </RequireAuth>
  );
}

export default ProfilePage;