import axios from "axios";

const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';
const token = process.env.REACT_APP_RECORDS_RAILS_CUSTOM_TOKEN;
const authToken = `Bearer ${token}`;


function deleteField(fieldId: string,  loadParent: () => void) {
    const headers = { 
      Authorization: authToken,
      Content_Type: 'application/json',
      Accept: 'application/json',
    };
    axios.delete(`${RECORDS_RAILS_URL}/fields/${fieldId}`, { headers })
      .then((response) => {
        loadParent();
      });
}

export default deleteField;