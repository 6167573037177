import { useState } from "react";
import { InputText, InputTextProps } from "../InputText";
import { IconButton } from "../IconButton";

export interface InputPasswordProps extends InputTextProps {}

export function InputPassword({ ...props }: InputPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex">
      <InputText
        {...props}
        type={showPassword ? "text" : "password"}
      ></InputText>

      <IconButton
        onClick={togglePassword}
        type="button"
        color="mutedInverted"
        icon={showPassword ? "visibility_off" : "visibility"}
        className="bg-transparent focus:bg-transparent active:bg-transparent hover:bg-transparent hover:text-gray-700 disabled:bg-transparent text-gray-400 pt-1"
        data-testid="password"
        disabled={props.disabled}
      ></IconButton>
    </div>
  );
}
