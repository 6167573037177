import { RequireAuth, useAuth } from "../LoginPage";
import axios from "axios";
import { SyntheticEvent, useEffect, useState, useRef, useLayoutEffect } from "react";
import { Icon } from "../../atoms/Icon/Icon";
import { InputText } from "../../atoms/InputText";
import { InputRadio } from "../../atoms/InputRadio/InputRadio";
import { InputCheckbox } from "../../atoms/InputCheckbox/InputCheckbox";
import { Select } from "../../atoms/Select/Select";
import Datepicker from "react-tailwindcss-datepicker";
import profileBg from '../../../assets/profile-bg.png'
import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  TabsHeaderProps,
  Tab,
  TabPanel,
  Tooltip,
} from "@material-tailwind/react";
import { Label } from "../../atoms/Label/Label";
import { InputNumber } from "../../atoms/InputNumber/InputNumber";
import { Toast, ToastStatus } from "../../molecules/Toast/Toast";
import { Button } from "../../atoms/Button/Button";
import AWS, { AWSError } from 'aws-sdk';
import { PromiseResult } from "aws-sdk/lib/request";
import { PutObjectOutput } from "aws-sdk/clients/s3";
import { InputPassword } from "../../atoms/InputPassword";
import { InputTextArea } from "../../atoms/InputTextArea/InputTextArea";
import { IconButton } from "../../atoms/IconButton";
import Breadcrumbs from "../../molecules/Breadcrumbs/Breadcrumbs";
// import Datepicker from "tailwind-datepicker-react"

const BURIPASS_TOKENINFO_URL = 'https://staging.pass.buri.dev/oauth/token/info';
const BURIPASS_USERINFO_URL = 'https://staging.pass.buri.dev/oauth/userinfo';
const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';

function Request(props: any) {
  let auth = useAuth();

  const current = props.current;
  const previous = props.previous;
  const field = props.field;
  
  let currentAnswer = null;
  let previousAnswer = null;
  
  const text_input = ["short_text", "paragraph", "url", "email", "time", "radio", "dropdown", "date", "file_upload"];

  function rejectRequest() {
    if (auth.user){
      const apiUrl = `${RECORDS_RAILS_URL}/field_entries/${current.id}`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
        Content_Type: 'application/json',
        Accept: 'application/json',
      };
      const payload = { 
        approval_status: 'rejected',
      };
      axios.patch(apiUrl, payload, { headers }).then((response) => {
        const res = response.data;
        props.setReload(true);
        props.setToast( 
        <Toast
          message="Request successfully rejected."
          toastStatus="success"
          onClose={() => props.setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        props.setIsToastOpen(true);
      }).catch(function(error) {
        props.setToast( 
        <Toast
          message="An error occurred."
          toastStatus="error"
          onClose={() => props.setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        props.setIsToastOpen(true);
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
    }
  }
  
  function approveRequest() {
    if (auth.user){
      const apiUrl = `${RECORDS_RAILS_URL}/field_entries/${current.id}`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
        Content_Type: 'application/json',
        Accept: 'application/json',
      };
      const payload = { 
        approval_status: 'approved',
      };
      axios.patch(apiUrl, payload, { headers }).then((response) => {
        const res = response.data;
        props.setReload(true);
        props.setToast( 
        <Toast
          message="Request successfully approved."
          toastStatus="success"
          onClose={() => props.setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        props.setIsToastOpen(true);
      }).catch(function(error) {
        props.setToast( 
        <Toast
          message="An error occurred."
          toastStatus="error"
          onClose={() => props.setIsToastOpen(false)}
          closeDelay={3000}
        ></Toast>);
        props.setIsToastOpen(true);
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
    }
  }

  if (text_input.includes(field.field_type)){
    currentAnswer = current.value_str;
    if (previous){
      previousAnswer = previous.value_str;
    }
  }
  else if (field.field_type == "number"){
    currentAnswer = current.value_int;
    if (previous){
      previousAnswer = previous.value_int;
    }
  }
  else if (field.field_type == "checkbox"){
    currentAnswer = current.value_bool;
    if (previous){
      previousAnswer = previous.value_bool;
    }
  }

  const text = previousAnswer ?
    <span> from <span className="text-black">"{previousAnswer}"</span></span> : <></>
  
  return (
    <li className={props.index == props.totalLength - 1 ? "py-4" : "border-b-2 py-4"}>
      <span className="w-2/3 inline-block align-top">Edited
        <span className="text-primary-600"> {field.display_name}</span>
        <span>{text} </span>
        to
        <span className="text-black"> "{currentAnswer}"</span>
      </span>
      <div className="flex space-x-4 float-right my-auto">
        <button type="button" onClick={approveRequest} className="items-center justify-center
          text-center px-4 
          rounded cursor-pointer
          disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
          transition outline outline-1 outline-success-500
          text-success-500 hover:outline-success-600 focus:outline-success-600">
          Verify
        </button>
        <button type="button" onClick={rejectRequest} className="items-center justify-center
          text-center px-4 
          rounded cursor-pointer
          disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
          transition outline outline-1 outline-error-500
          text-error-500 hover:outline-error-600 focus:outline-error-600">
          Reject
        </button>
      </div>
    </li>
  );
};

function User(props: any) {
  const req = props.request;
  
  let entryLength = 0;
  if (req.entries){
    entryLength = req.entries.length;
  }

  let entryList = <></>;
  if (req.entries){
    entryList = req.entries.map((entry: any, i: number) => (
      <Request
        key={entry.current.id}
        field={entry.field}
        current={entry.current}
        previous={entry.previous}
        totalLength={entryLength}
        index={i}
        setReload={props.setReload}
        setIsToastOpen={props.setIsToastOpen}
        setToast={props.setToast}
      />
    ));
  }

  const entryView = (
    <div className="font-normal text-left text-neutral-700 w-full h-full rounded-lg border border-neutral-200 p-6" style={{backgroundColor: "white"}}>
      <div className="flex">
        <div className="w-[8.5%] mr-8 p-0 align-middle flex truncate justify-center inline-block mb-auto"
          style={{borderRadius: "50%", aspectRatio: "1 / 1"}}>
          <img src={req.image_url}></img>
        </div>
        <h2 className="w-[35%] font-bold text-2xl mt-5">{req.name}</h2>
        <div className="inline-block w-full align-middle">
          <ul className="list-disc text-gray-600 list-inside mt-2.5 align-top">
            {entryList}
          </ul>
        </div>
      </div>
    </div>
  );
  
  return req.entries ? entryView : <></>;
};

function EditRequestsPage() {

  let auth = useAuth();

  const [requestList, setRequestList] = useState<any[]>([]);
  const [reload, setReload] = useState<boolean>(true);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [toast, setToast] = useState(<></>);
  
  const handleSearchChange = (e: SyntheticEvent) => {
    const newValue = (e.target as HTMLInputElement).value;
    setSearch(newValue);
    setReload(true);
  }

  const allRequests = requestList.map((req) => (
      <User
      key={req.id}
      request={req}
      setReload={setReload}
      setIsToastOpen={setIsToastOpen}
      setToast={setToast}
      />
    ));

  useEffect(() => {
    if (auth.user && reload){
      const apiUrl = `${RECORDS_RAILS_URL}/users?entry_status=pending&same_application=true&include=name&q=${search}`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(apiUrl, { headers }).then((response) => {
        const requests = response.data["users"];
        setRequestList(requests);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
      setReload(false)
    }
  }, [auth.user_id, reload]);

  return (
    <RequireAuth>
    <div className="h-full ml-4 min-h-screen p-4 pt-5">
      {isToastOpen ? (
        toast
        ) : (
        <></>
      )}
      <Breadcrumbs></Breadcrumbs>
      <div className="flex mt-6 w-full justify-between pr-4 align-middle mb-5 min-h-[3rem]" style={{color: '#6C2BDD'}}>
        <h2 className='text-3xl font-bold text-black'>Edit Requests</h2>
      </div>
      
      <div className="mt-4 font-normal text-left text-neutral-700 w-full h-full rounded-lg border border-neutral-200 p-6 py-10 space-y-10" style={{backgroundColor: "white"}}>  
        <div className="py-2 px-3 border border-gray-300 rounded flex items-center bg-white text-gray-700 w-3/5 print:hidden">
          <Icon name="search" className="text-2xl mr-2 text-gray-500 material-icon select-none"></Icon>
          <input
          // value={query} onChange={handleQueryChange}
            className="w-full text-md leading-tight appearance-none outline-none border-none"
            placeholder="Separate keywords with (,)"
            value={search}
            onChange={handleSearchChange}></input>
        </div>
        {allRequests}
      </div>
    </div>
    </RequireAuth>
  );
}

export default EditRequestsPage;