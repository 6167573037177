import { Icon } from "../../atoms/Icon/Icon";

export default function FilterList() {
    return (
      <div className="my-4 bg-gray-100 p-2 rounded-md">
        <div className="flex text-gray-500 mb-2">
          <Icon name="filter_list"></Icon>
          <span className="">FILTER</span>
        </div>
        <div className="flex bg-gray-200 p-1 w-fit rounded-2xl">
          <div className="text-gray-600">Status: RESIGNED/TERMINATED</div>
          <button>
            <Icon name="close"></Icon>
          </button>
        </div>
      </div>
    );
}