import { Icon } from "../../atoms/Icon/Icon";

interface IPageNavProps {
    firstElement?: number;
    lastElement?: number;
    totalCount?: number;
}
export default function PageNav({ firstElement, lastElement, totalCount }: IPageNavProps) {
    return (
      <div className="my-4">
        <div className="flex items-center justify-end">
          <div className="text-gray-500">{firstElement} - {lastElement} of {totalCount}</div>
          <div className="flex mx-4">
            <button className="mx-1 text-xl text-gray-300">
              <Icon name="first_page"></Icon>
            </button>
            <button className="mx-1 text-xl text-gray-300">
              <Icon name="navigate_before"></Icon>
            </button>
            <button className="mx-2 text-gray-500">1</button>
            <button className="mx-2 text-gray-500">2</button>
            <button className="mx-1 text-xl text-gray-600">
              <Icon name="navigate_next"></Icon>
            </button>
            <button className="mx-1 text-xl text-gray-600">
              <Icon name="last_page"></Icon>
            </button>
          </div>
          <div className="text-gray-500">Go to page </div>
          <div className="w-8">
            <input className="border border-gray-300 rounded w-8 mx-1 input-number-disable-arrows" type="number" />
          </div>
        </div>
      </div>
    );
  };