import { on } from "events";
import { Icon } from "../../atoms/Icon/Icon";

interface IAddFieldModalProps {
  onAddField: (fieldType: string) => void;
  onClose: () => void;
} 
export default function AddFieldModal({onAddField, onClose}: IAddFieldModalProps) {
  function onAddFieldRequest(fieldType: string) {
    onAddField(fieldType);
    onClose();
  }
  return (
    <div className="rounded border border-gray-400 max-w-xl mx-auto">
      <button className="text-white bg-gray-400 rounded w-4 m-1">
        <Icon name="chevron_left"></Icon>
      </button>
      <div className="text-center text-xl font-bold">Add Field</div>
      <div className="text-gray-500">
        <div className="text-lg font-bold ml-4 mt-4">Default Fields</div>
        <div className="grid grid-cols-3 gap-4 m-4">
          <button
            type="button"
            onClick={() => onAddFieldRequest('short_text')} 
            className="bg-gray-100 rounded text-center p-4 h-32">
            <Icon className="text-7xl" name="text_fields"></Icon>
            <div className="">Text</div>
          </button>
          <button 
            type="button"
            onClick={() => onAddFieldRequest('number')} 
            className="bg-gray-100 rounded text-center p-4 h-32">
            <Icon className="text-7xl" name="looks_one"></Icon>
            <div className="">Number</div>
          </button>
          <button 
            type="button"
            onClick={() => onAddFieldRequest('date')}
            className="bg-gray-100 rounded text-center p-4 h-32">
            <Icon className="text-7xl" name="event"></Icon>
            <div className="">Date</div>
          </button>
          <button 
            type="button"
            onClick={() => onAddFieldRequest('dropdown')}
            className="bg-gray-100 rounded text-center p-4 h-32">
            <Icon className="text-7xl" name="arrow_drop_down_circle"></Icon>
            <div className="">Dropdown</div>
          </button>
          <button 
            type="button"
            onClick={() => onAddFieldRequest('radio')}
            className="bg-gray-100 rounded text-center p-4 h-32">
            <Icon className="text-7xl" name="radio_button_checked"></Icon>
            <div className="">Radio Button</div>
          </button>
          <button 
            type="button"
            onClick={() => onAddFieldRequest('checkbox')}
            className="bg-gray-100 rounded text-center p-4 h-32">
            <Icon className="text-7xl" name="check_box"></Icon>
            <div className="">Checkbox</div>
          </button>
          <button 
            type="button"
            onClick={() => onAddFieldRequest('paragraph')}
            className="bg-gray-100 rounded text-center p-4 h-32">
            <Icon className="text-7xl" name="notes"></Icon>
            <div className="">Text Area</div>
          </button>
        </div>
      </div>
    </div>
  );
}