import React from 'react';
import { fakeAuthProvider } from './auth';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '../../atoms/Icon/Icon';
import AuthorizationCode from './AuthorizationCode';
import axios from 'axios';

const clientId = '3dfd73be42760397af42e5a56fb87331690f89eb9201551184f538063938a912';
const clientSecret = '';
const oauthServerUrl = 'https://staging.pass.buri.dev';

interface AuthContextType {
    user: any;
    user_id: any;
    is_admin: any;
    access_token: any;
    refresh_token: any;
    signin: (user: string, user_id: string, is_admin: boolean, access_token: string, refresh_token: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
}
  
let AuthContext = React.createContext<AuthContextType>(null!);
  
function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser ] = React.useState<any>(null);
  let [user_id, setUserID ] = React.useState<any>(null);
  let [is_admin, setIsAdmin ] = React.useState<any>(null);
  let [access_token, setAccessToken ] = React.useState<any>(null);
  let [refresh_token, setRefreshToken ] = React.useState<any>(null);

  let signin = (newUser: string, newUserID: string, isAdmin: boolean, accessToken: string, refreshToken: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setUser(newUser);
      setUserID(newUserID);
      setIsAdmin(isAdmin);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(()=> {
      setUser(null);
      setUserID(null);
      setIsAdmin(null);
      setAccessToken(null);
      setRefreshToken(null);
      callback();
    });
  };
    
  const user_info = localStorage.getItem('hris_buripass_user_info')
  if (user_info && user == null){
    const user_json = JSON.parse(user_info);
    const payload = { 
      grant_type: 'refresh_token',
      client_id: `${clientId}`,
      client_secret: `${clientSecret}`,
      refresh_token: `${user_json.refresh_token}`,
    };
    const headers = {
        'content-type': 'application/json',
    };
    const apiUrl = `${oauthServerUrl}/oauth/token`;
    axios.post(apiUrl, payload, { headers }).then((response) => {
      const pass_data = response.data;
      signin(user_json.name, user_json.uuid, user_json.is_admin, pass_data.access_token, pass_data.refresh_token, () => {});
      const user_info = {
        name: user_json.name,
        uuid: user_json.uuid,
        is_admin: user_json.is_admin,
        refresh_token: pass_data.refresh_token
      }
      localStorage.setItem('hris_buripass_user_info', JSON.stringify(user_info)); //changed
    }).catch(function(error) {
      let e = error;
      if (error.response) {
        e = error.response.data;                   // data, status, headers
        if (error.response.data && error.response.data.error) {
          e = error.response.data.error;           // my app specific keys override
        }
      } else if (error.message) {
        e = error.message;
      } else {
        e = "Unknown error occured";
      }
      console.log(e);
    });
  }
  
  let value = { user, user_id, is_admin, access_token, refresh_token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
  
function useAuth() {
    return React.useContext(AuthContext);
}

function AuthStatus() {
    let auth = useAuth();
    let navigate = useNavigate();
  
    if (!auth.user) {
      return <p>You are not logged in.</p>
    }
  
    return (
      <p>
        Welcome {auth.user}!{" "}
        {/* <button
          onClick={() => {
              auth.signout(() => navigate("/"));
            }}
        >
            <Icon name='logout'></Icon>
        </button> */}
      </p>
    );
}
  
function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
  
    if (!auth.user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
}

function LoginPage({updateToken}: {updateToken: React.Dispatch<React.SetStateAction<string>>}) {

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    let from = location.state?.from?.pathname || "/";

    // function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    //     event.preventDefault();

    //     let formData = new FormData(event.currentTarget);
    //     let username = formData.get("username") as string;

    //     auth.signin(username, () => {
    //         navigate(from, { replace: true });
    //     });
    // }

    return (
        <div>
            <p>You must log in to view the page at {from}.</p>

            <AuthorizationCode addToken={updateToken} fromRoute={from}/>

            {/* <form onSubmit={handleSubmit}>
                <label className='block'>
                    <span className='block text-sm font-medium text-slate-700 m-2'>
                    Username: 
                    </span>
                    <input className="after:content-['*'] after:ml-0.5 after:text-red-500 mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 md:w-1/2 sm:w-full" name='username' type='text' />
                </label>{" "}
                <button className='rounded-full bg-purple-500 text-white text-center px-4 py-2 m-2' type='submit'>Login</button>
            </form> */}
        </div>
    );
}

export { AuthProvider, AuthStatus, useAuth, RequireAuth, LoginPage };