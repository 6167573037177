import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { AuthProvider, LoginPage, useAuth } from './components/pages/LoginPage';
import DefaultLayout from './components/templates/DefaultLayout/DefaultLayout';
import ProfilePage from './components/pages/ProfilePage/ProfilePage';
import SettingsPage from './components/pages/SettingsPage/SettingsPage';
import EmployeeListPage from './components/pages/EmployeeListPage/EmployeeListPage';
import FieldGroupDetailPage from './components/pages/FieldGroupDetailPage/FieldGroupDetailPage';
import FieldDetailPage from './components/pages/FieldDetailPage/FieldDetailPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Icon } from './components/atoms/Icon/Icon';
import Breadcrumbs from './components/molecules/Breadcrumbs/Breadcrumbs';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';
import { IconButton } from './components/atoms/IconButton';
import axios from 'axios';
import EditRequestsPage from './components/pages/EditRequestsPage/EditRequestsPage';

// Create a client
const queryClient = new QueryClient();

const clientId = '3dfd73be42760397af42e5a56fb87331690f89eb9201551184f538063938a912';
const clientSecret = '';
const oauthServerUrl = 'https://staging.pass.buri.dev';

function App() {  
  const[token, setToken]=React.useState('');

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <div className='h-full'>
          <BrowserRouter>
            <Routes>
              <Route element={<DefaultLayout updateToken={setToken}/>}>
                <Route path='/' element={<Home accessToken={token} updateToken={setToken}/>} />
                <Route path='/login' element={<LoginPage updateToken={setToken}/>} />
                <Route path='/edit-requests' element={<EditRequestsPage />} />
                <Route path='/settings' element={<SettingsPage />} />
                <Route path='/settings/fieldgroup/:id' element={<FieldGroupDetailPage />} />
                <Route path='/settings/field/:id' element={<FieldDetailPage />} />
                <Route path='*' element={<NoMatch />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </AuthProvider>
    </QueryClientProvider>
  );
}
export default App;

function Home(props: any) {
  
  const accessToken = props.accessToken

  let auth = useAuth();

  useEffect(() => {
    if (auth && auth.user){
      const interval = setInterval(() => {
        const refreshToken = auth.refresh_token;
        const name = auth.user;
        const uuid = auth.user_id;
        const is_admin = auth.is_admin;
        const payload = { 
          grant_type: 'refresh_token',
          client_id: `${clientId}`,
          client_secret: `${clientSecret}`,
          refresh_token: `${refreshToken}`,
        };
        const headers = {
            'content-type': 'application/json',
        };
        const apiUrl = `${oauthServerUrl}/oauth/token`;
        axios.post(apiUrl, payload, { headers }).then((response) => {
          const pass_data = response.data;
          props.updateToken(pass_data.access_token);
          auth.signin(name, uuid, is_admin, pass_data.access_token, pass_data.refresh_token, () => {});
          const user_info = {
            name: name,
            uuid: uuid,
            is_admin: is_admin,
            refresh_token: pass_data.refresh_token
          }
          localStorage.setItem('hris_buripass_user_info', JSON.stringify(user_info)); //changed
        }).catch(function(error) {
          let e = error;
          if (error.response) {
            e = error.response.data;                   // data, status, headers
            if (error.response.data && error.response.data.error) {
              e = error.response.data.error;           // my app specific keys override
            }
          } else if (error.message) {
            e = error.message;
          } else {
            e = "Unknown error occured";
          }
          console.log(e);
        });
        /*
            Run a function or set any state here
        */
      }, 6600000);
      return () => clearInterval(interval);
    }
  }, [auth]);
  
  const [activeTab, setActiveTab] = React.useState("profile");
  
  let navigate = useNavigate(); 
  const toSettings = () =>{ 
    let path = `/settings`; 
    navigate(path);
  }

  const data = [
    {
      label: "Profile",
      value: "profile",
      page: <ProfilePage/>,
    },
    {
      label: "Employees",
      value: "employees",
      page: <EmployeeListPage />,
    }
  ];

  return (
    <div className="h-full ml-4 min-h-screen p-4 pt-5">
      <Breadcrumbs></Breadcrumbs>
      <div className="flex mt-6 w-full justify-between pr-4 align-middle mb-5 min-h-[3rem]" style={{color: '#6C2BDD'}}>
        <div className='flex items-center'>
          <Icon name='people' className="text-4xl mr-1.5"></Icon>
          <h2 className='text-3xl font-bold'>Employee Directory</h2>
        </div>
        {
          auth.is_admin ? 
          <IconButton icon='settings' type="button" color="mutedInverted" className="text-2xl" onClick={toSettings}></IconButton> :
          <></>
        }
      </div>
      <Tabs value={activeTab}>
        <TabsHeader
          className="rounded-none border-b-2 border-blue-gray-50 bg-transparent p-0 text-neutral-500 flex justify-start mr-4"
          indicatorProps={{
            style: {display: "none"}
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              style={activeTab === value ? {color:"#4B1F97", borderColor: "#4B1F97"} : {}}
              className={activeTab === value ? "font-bold rounded-t-lg bg-white w-auto border-b-2 px-2 -mb-0.5": "font-bold w-auto px-2 -mb-0.5"}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value, page }) => (
            <TabPanel key={value} value={value} className="px-0 pt-0">
              {page}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2 className='text-3xl text-gray-700 font-bold mb-5'>Nothing to see here!</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  );
}