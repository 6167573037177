import React from "react";
import { Link, Outlet } from "react-router-dom";
import LeftNavbar from "../../molecules/LeftNavbar/LeftNavbar";
import Footer from "../../molecules/Footer/Footer";
import TopNavbar from "../../molecules/TopNavbar/TopNavbar";

function DefaultLayout({updateToken}: {updateToken: React.Dispatch<React.SetStateAction<string>>}) {
    return (
      <div className="w-full h-full">
        <TopNavbar updateToken={updateToken}></TopNavbar>

        <div className='w-full h-full flex'>

          <div className="w-16">
            <LeftNavbar></LeftNavbar>
          </div>

          <div className='w-full h-full'>
            <Outlet />

            <Footer></Footer>
          </div>
    
        </div>
      </div>
    );
  }

  export default DefaultLayout;