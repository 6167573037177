import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import OAuth2Login from 'react-simple-oauth2-login';
import ErrorAlert from './ErrorAlert';
import { useAuth } from './LoginPage';
import axios from "axios";

const railsToken = process.env.REACT_APP_RECORDS_RAILS_CUSTOM_TOKEN;
const appServerUrl = 'https://bokchoy.records.rails.buri.dev';
const userUrl = `${appServerUrl}/users`;
const oauthServerUrl = 'https://staging.pass.buri.dev';
const authorizationUrl = `${oauthServerUrl}/oauth/authorize`;
const clientId = '3dfd73be42760397af42e5a56fb87331690f89eb9201551184f538063938a912';
const redirectUri = process.env.REACT_APP_RECORDS_REDIRECT_URI;
const scope = 'openid+profile+email';
const clientSecret = '';

export default function AuthorizationCode({addToken, fromRoute}) {
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    const onSuccess = ({code}) => fetch(`${oauthServerUrl}/oauth/token`, {
        method: 'POST',
        body: JSON.stringify({ 
          grant_type: 'authorization_code',
          client_id: `${clientId}`,
          client_secret: `${clientSecret}`,
          redirect_uri: `${redirectUri}`,
          code: code,
         }),
        headers: {
            'content-type': 'application/json',
        },
    })
      .then(res => res.json())
      .then((data) => {
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
        return data.access_token;
      })
      .then(token => fetch(`${oauthServerUrl}/oauth/userinfo`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            authorization: `Bearer ${token}`,
        },
      }))
      .then(res => res.json())
      .then(setUser)
      .catch(setError);
    
    useEffect(() =>{
      addToken(accessToken);
    }, [accessToken])
    
    let auth = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
      if (auth.user){
        navigate(fromRoute, { replace: true });
      }
    }, [auth])

    useEffect(() =>{
      if (user && accessToken && refreshToken){
        const apiUrl = `${userUrl}/${user.sub}/`;
        const headers = {
          'Authorization': `Bearer ${railsToken}`,
        };
        axios.get(apiUrl, { headers }).then((response) => {
          const is_admin = response.data["is_admin"];
          auth.signin(user.given_name.concat(" ",user.family_name), user.sub, is_admin, accessToken, refreshToken, () => {
            navigate(fromRoute, { replace: true });
          });
          const user_info = {
            name: user.given_name.concat(" ",user.family_name),
            uuid: user.sub,
            is_admin: is_admin,
            refresh_token: refreshToken
          }
          localStorage.setItem('hris_buripass_user_info', JSON.stringify(user_info)); //changed
        }).catch(function(error) {console.log(error)});
      }
    }, [user, accessToken, refreshToken])

    return (
        <div>
          <div className="column">
            {
              error && <ErrorAlert error={error} />
            }
            <OAuth2Login
              id="auth-code-login-btn"
              className="flex items-center justify-center uppercase text-center py-2 px-4 rounded cursor-pointer bg-purple-500 text-white"
              authorizationUrl={authorizationUrl}
              clientId={clientId}
              redirectUri={redirectUri}
              responseType="code"
              scope={scope}
              buttonText="Login"
              onSuccess={onSuccess}
              onFailure={setError}
            />
            {
              accessToken && (
                <div>
                  <p className="text-center">Loading...</p>
                </div>
              )
            }
          </div>
        </div>
    );
}