import { Icon } from "../../atoms/Icon/Icon";
import AddField from "../AddField/AddField";
import EditField from "../EditField/EditField";
import Modal from "../../molecules/Modal/Modal";
import { useEffect, useState } from "react";
import DeleteModal from "../../molecules/DeleteModal/DeleteModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../pages/LoginPage";

const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';
const token = process.env.REACT_APP_RECORDS_RAILS_CUSTOM_TOKEN;
const authToken = `Bearer ${token}`;

interface IFieldGroupProps {
  field_group_id?: string;
  field_group_name?: string;
  order_id?: number;
  application_id?: string;
  created_at?: string;
  updated_at?: string;
  loadParent?: any;
  onFieldGroupDetail?: boolean;
};
export default function FieldGroup(props: IFieldGroupProps) {
  let auth = useAuth();
  const navigate = useNavigate();
  const fieldGroupId = props.field_group_id;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fields, setFields] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fieldGroupName, setFieldGroupName] = useState(props.field_group_name);
  const [orderId, setOrderId] = useState(props.order_id);
  const fieldsLength = fields.length;

  function onModalCloseRequest() {
    setIsModalOpen(false);
  };
  function createField(field: any) {
    if (auth.access_token){
      const headers = { 
        Authorization: `Bearer ${auth.access_token}`,
        Content_Type: 'application/json',
        Accept: 'application/json',
      };
      const payload = {
        name: field.name,
        field_type: field.field_type,
        is_required: field.is_required,
        order_id: field.order_id,
        can_employee_update: field.can_employee_update,
      }
      axios.post(`${RECORDS_RAILS_URL}/field_groups/${fieldGroupId}/fields`, 
        payload, { headers })
        .then((response) => {
          props.loadParent();
        });
    }
  }
  function onAddFieldRequest(fieldType: string) {
    const underscoreToSpace = fieldType.replace(/_/g, " ");
    const capitalizeEachWord = underscoreToSpace.replace(/\b\w/g, (l) => l.toUpperCase());
    const newField = {
      name: `New ${capitalizeEachWord} Field`,
      field_type: fieldType,
      is_required: false,
      order_id: fieldsLength + 1,
      can_employee_update: true,
    };
    createField(newField);
  }
  function updateFieldGroup() {
    setSaving(true);
    if (auth.access_token){
      const apiUrl = `${RECORDS_RAILS_URL}/field_groups/${fieldGroupId}`;
      const payload = {
        name: fieldGroupName,
        order_id: orderId,
      }
      axios.put(apiUrl, payload, 
        { headers: { Authorization: `Bearer ${auth.access_token}` } })
        .then((response) => {
          console.log(response)
          setSaving(false);
      });
    }
  }
  function onSaveRequest() {
    updateFieldGroup();
    navigate(`/settings/fieldgroup/${fieldGroupId}`);
  }
  function deleteFieldGroup() {
    if (auth.access_token){
      const headers = { 
        Authorization: `Bearer ${auth.access_token}`,
        Content_Type: 'application/json',
        Accept: 'application/json',
      };
      axios.delete(`${RECORDS_RAILS_URL}/field_groups/${fieldGroupId}`, { headers })
        .then((response) => {
          navigate("/settings");
        });
    }
  }

  const typesWithOptions = ["dropdown", "checkbox", "radio"];
  const fieldList = fields.map((field) => (
    <EditField
      key={field.id}
      id={field.id}
      name={field.name}
      order_id={field.order_id}
      field_group_id={field.field_group_id}
      is_required={field.is_required}
      field_type={field.field_type}
      created_at={field.created_at}
      updated_at={field.updated_at}
      width="full" 
      can_employee_update={field.can_employee_update}
      adminEdit={!field.can_employee_update} 
      addOptions={typesWithOptions.includes(field.field_type)}
      onFieldDetail={false}
    />
  ));

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${RECORDS_RAILS_URL}/field_groups/${fieldGroupId}/fields`;
    const authToken = `Bearer ${token}`;
    axios.get(apiUrl, { headers: { Authorization: authToken } })
      .then((response) => {
        const allFields = response.data.fields;
        setFields(allFields);
        setLoading(false);
    });
  }, [fieldsLength, fieldGroupId]);

  const loadingTemplate = (
    <p className="text-center text-gray-500">Loading...</p>
  );

  return (
    <div className="">
      <div className="flex justify-end">
        {saving && <p>Saving...</p>}
        {props.onFieldGroupDetail ? (
            <button type="button" onClick={() => onSaveRequest()} 
              className="rounded bg-purple-700 text-white text-md ml-2 py-1 px-4">SAVE</button>
        ) : null}
      </div>
      <div className="flex w-full h-full rounded border border-purple-400 my-4 py-4">
        <div className="">
          {/* <button className="">
            <Icon className="mx-4 text-xl" name="unfold_more"></Icon>
          </button> */}
          <input type="number" onChange={(e) => setOrderId(parseInt(e.target.value))} value={orderId} placeholder="Order ID" 
            className="border border-t-0 border-l-0 border-r-0 rounded w-8 ml-1 border-gray-300"/>
        </div>
        <div className="w-full mr-4">
          <div className="flex w-full text-xl font-bold modal-parent">
            <input type="text" onChange={(e) => setFieldGroupName(e.target.value)} value={fieldGroupName} placeholder="Field Group Name" 
              className="border border-t-0 border-l-0 border-r-0 rounded w-full border-gray-300" />
            <button className="" type="button" onClick={() => setIsModalOpen(true)}>
              <Icon name="more_vert"></Icon>
            </button>
            <Modal isOpen={isModalOpen} onCloseRequest={onModalCloseRequest}>
                {props.onFieldGroupDetail ? (
                  <DeleteModal deleteItem={deleteFieldGroup}></DeleteModal>
                ) : (
                  <div className='w-full text-sm font-medium text-gray-700 mb-2'>
                    <button type="button"  onClick={() => navigate(`/settings/fieldgroup/${fieldGroupId}`)}
                        className='w-fit text-left'>Edit</button>
                  </div>
                )}
            </Modal>
          </div>

          {/* ADD FIELDS BUTTONS */}
          {!props.onFieldGroupDetail ? null : (
            <div className="">
              <AddField onAddFieldRequest={onAddFieldRequest} width="full"></AddField>
              <AddField onAddFieldRequest={onAddFieldRequest} width="half"></AddField>
            </div>
          )}

          {/* EDIT FIELDS / LIST OF FIELDS */}
          <div className="grid grid-cols-6 gap-1">
            {loading ? loadingTemplate : fieldList}
          </div>

        </div>
      </div>
    </div>
  );
}