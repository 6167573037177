import React, { useEffect } from 'react';

interface IModalProps {
    isOpen: boolean;
    onCloseRequest(data: object): void;
    children?: any;
    modalPos?: 'center' | 'left' | 'right';
}

function useOutsideAlerter(ref: any, onCloseRequest: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onCloseRequest();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onCloseRequest]);
}

export default function Modal({ isOpen = false, onCloseRequest, children, modalPos = 'right' }: IModalProps ) {    
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, onCloseRequest);

    // if isOpen is false, don't render anything
    if (!isOpen) {
        return null;
    }

    const modalPosCenter = 'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
    const modalPosLeft = 'absolute top-1/2 left-12 transform -translate-x-1/2 -translate-y-1/2';
    const modalPosRight = 'absolute top-1/2 right-12 transform translate-x-1/2 -translate-y-1/2';
    const modalPosSet = modalPos === 'center' ? modalPosCenter : modalPos === 'left' ? modalPosLeft : modalPosRight;
    const modalClassname = `w-fit rounded border border-shadow bg-white px-4 py-3 ${modalPosSet} z-10`;
    // if isOpen is true, render the modal
    return (
        <div ref={wrapperRef} className={modalClassname}>
            {children}
        </div>
    );
};
