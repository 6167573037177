import { Button, ButtonProps } from "../Button/Button";
import { Icon } from "../Icon/Icon";

export interface IconButtonProps extends ButtonProps {
  icon?: string;
  iconPosition?: "left" | "right";
  text?: string;
}

export function IconButton({
  icon = "add",
  type,
  color = "primary",
  iconPosition = "left",
  text,
  ...props
}: IconButtonProps) {
  return (
    <Button type={type} color={color} {...props}>
      {iconPosition === "left" ? (
        <>
          <Icon name={icon} />
          {props.children || text}
        </>
      ) : (
        <>
          {props.children || text}
          <Icon name={icon} />
        </>
      )}
    </Button>
  );
}
