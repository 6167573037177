import { useEffect, useState } from "react"
import EditField from "../../organisms/EditField/EditField"
import deleteField from "../../organisms/EditField/fields";
import Breadcrumbs from "../../molecules/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { RequireAuth, useAuth } from "../LoginPage";

const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';
const token = process.env.REACT_APP_RECORDS_RAILS_CUSTOM_TOKEN;
const authToken = `Bearer ${token}`;

export default function FieldDetailPage() {
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [field, setField] = useState(
        {
            id: "",
            name: "",
            order_id: 0,
            field_group_id: "",
            is_required: false,
            field_type: "",
            created_at: "",
            updated_at: "",
            can_employee_update: false,
        }
    );
    const typesWithOptions = ["dropdown", "checkbox", "radio"];
    
    function loadParent() {
        window.location.reload();
    }
    function onDeleteFieldRequest(fieldId: string) {
        deleteField(fieldId, loadParent);
    }

    useEffect(() => {
        setLoading(true);
        const apiUrl = `${RECORDS_RAILS_URL}/fields/${id}`;
        axios.get(apiUrl, { headers: { Authorization: authToken } })
          .then((response) => {
            console.log(response)
            setField(response.data);
            setLoading(false);
        });
    }, [id]);

    const detailTemplate = (
        <EditField
            key={field.id}
            id={field.id}
            name={field.name}
            order_id={field.order_id}
            field_group_id={field.field_group_id}
            is_required={field.is_required}
            field_type={field.field_type}
            created_at={field.created_at}
            updated_at={field.updated_at}
            width="full" 
            can_employee_update={field.can_employee_update}
            adminEdit={!field.can_employee_update} 
            addOptions={typesWithOptions.includes(field.field_type)}
            onFieldDetail={true}
        />
    )
    const loadingTemplate = (
        <p className="text-center text-gray-500">Loading...</p>
      );
    return (
        <RequireAuth>
            <div className="m-4 h-full">
                <Breadcrumbs></Breadcrumbs>

                <div className="flex justify-between mb-4">
                    <div className="flex text-2xl">
                        <h2 className='text-gray-700 font-bold'>Field Details</h2>
                    </div>
                </div>

                {loading ? loadingTemplate : detailTemplate}
            </div>
        </RequireAuth>
    )
}