import React from "react";

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children?: React.ReactNode;
}

export function Select({ children, ...props }: SelectProps) {
  return (
    <select {...props} className={`w-full border border-gray-300 rounded disabled:text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-100 ${props.className}`}>
      {children}
    </select>
  );
}
