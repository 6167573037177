import { useEffect, useState } from "react";
import { Icon } from "../../atoms/Icon/Icon";
import Breadcrumbs from "../../molecules/Breadcrumbs/Breadcrumbs";
import FieldGroup from "../../organisms/FieldGroup/FieldGroup";
import axios from "axios";
import Modal from "../../molecules/Modal/Modal";
import { RequireAuth, useAuth } from "../LoginPage";

const BURIPASS_TOKENINFO_URL = 'https://staging.pass.buri.dev/oauth/token/info';
const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';

function AddSectionModal(props: any) {
  const [sectionName, setSectionName] = useState('New Section');
  const [orderId, setOrderId] = useState(props.nextOrderId);

  function createFieldGroup() {
    const apiUrl = `${RECORDS_RAILS_URL}/tabs/${props.tabId}/field_groups`;
    const headers = { 
        Authorization: `Bearer ${props.auth.access_token}`,
        Content_Type: 'application/json',
        Accept: 'application/json',
      };
      const payload = {
        name: sectionName,
        order_id: orderId,
      }
      axios.post(apiUrl, payload, { headers })
        .then((response) => {
          props.loadFieldGroups();
          props.onCloseRequest();
        });
  }
  return (
    <div className="rounded max-w-xl mx-auto text-left">
      <div className="w-full text-gray-700 p-4">Please insert section name and order</div>
        <input onChange={(e) => setSectionName(e.target.value)} value={sectionName}
          className="w-fit rounded border border-gray-300 p-2" type="text" placeholder="Section name" />
        <input onChange={(e) => setOrderId(e.target.value)} value={orderId}
          className="w-16 rounded border border-gray-300 p-2" type="number" placeholder="Order ID" />
        <div className="flex w-full justify-between p-4">
          <button onClick={props.onCloseRequest} 
            className="rounded bg-gray-200 text-gray-700 text-md py-2 px-4 mr-2">CANCEL</button>
          <button onClick={createFieldGroup} 
            className="rounded bg-purple-600 text-white text-md py-2 px-4 mr-2 hover:bg-purple-800">OK</button>
        </div>
    </div>
  );
}

export default function SettingsPage() {
  let auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [fieldGroups, setFieldGroups] = useState<any[]>([]);
  const [isAddSectionModalOpen, setisAddSectionModalOpen] = useState(false);
  const [application_uuid, setApplicationUUID] = useState("");
  const [tabId, setTabId] = useState("");

  function loadFieldGroups() {
    setLoading(true);
    if (auth.access_token){
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(
        `${RECORDS_RAILS_URL}/tabs/${tabId}/field_groups`, 
        { headers })
        .then((response) => {
          const allFieldGroups = response.data.field_groups;
          setFieldGroups(allFieldGroups);
          setLoading(false);
      });
    }
  }

  function onAddSectionModalCloseRequest() {
    setisAddSectionModalOpen(false);
  }

  const fieldGroupList = fieldGroups.map((fieldGroup) => (
    <FieldGroup
      key={fieldGroup.id}
      field_group_id={fieldGroup.id}
      field_group_name={fieldGroup.name}
      order_id={fieldGroup.order_id}
      application_id={fieldGroup.application_id}
      created_at={fieldGroup.created_at}
      updated_at={fieldGroup.updated_at}
      loadParent={loadFieldGroups}
      onFieldGroupDetail={false}
    />
  ));

  const fieldGroupLength = fieldGroups.length;

  useEffect(() => {
    if (auth.access_token){
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(BURIPASS_TOKENINFO_URL, { headers }).then((response) => {
        const pass_data = response.data;
        setApplicationUUID(pass_data.application.uuid);
      }).catch(function(error) {console.log(error)});
    }
  }, [application_uuid]);

  useEffect(() => {
    if (application_uuid){
      const apiUrl = `${RECORDS_RAILS_URL}/applications/${application_uuid}/tabs?parent=employee_information`;
      const headers = {
        'Authorization': `Bearer ${auth.access_token}`,
      };
      axios.get(apiUrl, { headers }).then((response) => {
        const tabs_data = response.data["tabs"];
        setTabId(tabs_data[0].id);
      }).catch(function(error) {
        let e = error;
        if (error.response) {
          e = error.response.data;                   // data, status, headers
          if (error.response.data && error.response.data.error) {
            e = error.response.data.error;           // my app specific keys override
          }
        } else if (error.message) {
          e = error.message;
        } else {
          e = "Unknown error occured";
        }
        console.log(e);
      });
    }
  }, [application_uuid]);

  useEffect(() => {
    if (tabId && fieldGroupLength == 0) {
      loadFieldGroups();
    }
  }, [tabId, fieldGroupLength]);

  const loadingTemplate = (
    <p className="text-center text-gray-500">Loading...</p>
  );

  return (
    <RequireAuth>
      <div className="m-4 h-full">
        <Breadcrumbs></Breadcrumbs>

        <div className="flex justify-between mb-4">
          <div className="flex text-2xl">
            <div className="text-gray-500">
              <Icon name="settings"></Icon>
            </div>
            <h2 className='text-gray-700 font-bold'>Settings</h2>
          </div>

          <div className="flex justify-end">
            <button className="flex text-purple-700 mr-2 pt-1">
              <div className="text-lg">
                <Icon name="download"></Icon>
              </div>
              <div className="">Export CSV</div>
            </button>
          </div>
        </div>

        <div className="w-full modal-parent">
          <button type="button" onClick={() => setisAddSectionModalOpen(true)} 
            className="flex justify-center w-full rounded border border-purple-700 text-purple-700 text-md my-4">
            <Icon className="mr-1" name="add_circle"></Icon>
            <div className="ml-1">ADD SECTION</div>
          </button>
          <Modal isOpen={isAddSectionModalOpen} onCloseRequest={onAddSectionModalCloseRequest} modalPos="center">
              <AddSectionModal 
                auth={auth}
                tabId={tabId}
                onCloseRequest={onAddSectionModalCloseRequest} 
                loadFieldGroups={loadFieldGroups}
                nextOrderId={fieldGroupLength + 1}></AddSectionModal>
          </Modal>
        </div>
        
        {/* LIST OF FIELD GROUPS */}
        {loading ? loadingTemplate : fieldGroupList}

      </div>
    </RequireAuth>
  );
}