import React from "react";
import { InputTextProps } from "../InputText";

export interface InputNumberProps extends InputTextProps {}

export function InputNumber(props: InputNumberProps) {
  return (
    <input
      {...props}
      type="number"
      className={`w-full border border-gray-300 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-100 ${props.className}`}
    />
  );
}
