import { Icon } from "../../atoms/Icon/Icon";

export default function Breadcrumbs() {
    return (
        <div className="flex text-sm mb-4">
          <button className="text-white bg-gray-400 rounded mr-1">
            <Icon name="chevron_left"></Icon>
          </button>
          <div className="text-gray-400 mx-1">...</div>
          <div className="text-gray-400 mx-1">
            <Icon name="chevron_right"></Icon>
          </div>
          <a href="#" className="text-gray-400 mx-1">SCREEN 2</a>
          <div className="text-gray-400 mx-1">
            <Icon name="chevron_right"></Icon>
          </div>
          <a href="#" className="text-gray-400 mx-1">SCREEN 3</a>
        </div>
    );
};