import { InputCheckbox } from "../../atoms/InputCheckbox/InputCheckbox";

interface IRowProps {
    user: any;
}

export default  function Row(props: IRowProps) {
    const user = props.user;
    const submissions = user.entries;
    const nameSubmision = submissions[0];
    const emailSubmision = submissions[1];
    const deptSubmision = submissions[2];
    const statusSubmision = submissions[3];
    const name = nameSubmision.field_entry.value_str;
    const email = emailSubmision.field_entry.value_str;
    const department = deptSubmision.field_entry.value_str;
    const status = statusSubmision.field_entry.value_str;
    return (
      <tr className="py-2 border-b">
        <td className="my-1 font-normal text-left text-gray-500">
          <InputCheckbox></InputCheckbox>
        </td>
        <td className="py-2">{name ? name : user.id}</td>
        <td className="py-2">{email}</td>
        <td className="py-2">{department}</td>
        <td className="py-2">
          <button className="rounded-full bg-gray-400 w-fit h-fit px-2 text-white">
            {status}
          </button>
        </td>
      </tr>
    );
};