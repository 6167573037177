import { useState } from "react";
import { Icon } from "../../atoms/Icon/Icon";
import { InputCheckbox } from "../../atoms/InputCheckbox/InputCheckbox";
import Modal from "../../molecules/Modal/Modal";
import DeleteModal from "../../molecules/DeleteModal/DeleteModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../pages/LoginPage";

const RECORDS_RAILS_URL = 'https://bokchoy.records.rails.buri.dev';
const token = process.env.REACT_APP_RECORDS_RAILS_CUSTOM_TOKEN;
const authToken = `Bearer ${token}`;

interface ISelectFieldTypeProps {
  field_type: string;
  changeFieldType: (e: any) => void;
}
interface IEditFieldProps {
  width: string;
  id: string;
  name: string;
  order_id: number;
  can_employee_update: boolean;
  field_group_id: string;
  is_required: boolean;
  field_type: string;
  created_at: string;
  updated_at: string;
  requestEdit?: boolean;
  adminEdit?: boolean;
  addOptions?: boolean;
  onDeleteRequest?: (fieldId: string) => void;
  onFieldDetail?: boolean;
}
export default function EditField({requestEdit, adminEdit, addOptions, width, ...props}: IEditFieldProps) {
  let auth = useAuth();
  const navigate = useNavigate();
  const fieldId = props.id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fieldName, setFieldName] = useState(props.name);
  const [fieldType, setFieldType] = useState(props.field_type);
  const [isRequired, setIsRequired] = useState(props.is_required);
  const [orderId, setOrderId] = useState(props.order_id);
  const [showOptions, setShowOptions] = useState(addOptions);
  const [optionCount, setOptionCount] = useState(1);
  const typesWithOptions = ["dropdown", "checkbox", "radio"];

  function onModalCloseRequest() {
    setIsModalOpen(false);
  };

  function RequestEdit() {
    return (
      <div className="flex">            
        <Icon name="security"></Icon>
        <div className="ml-1">Allow edit request</div>
      </div>
    );
  };
  function AdminEdit() {
    return (
      <div className="flex">
        <Icon name="security"></Icon>
        <div className="ml-1">Admin only access</div>
      </div>
    );
  };
  function PublicEdit() {
    return (
      <div className="flex">
        <Icon name="people"></Icon>
        <div className="ml-1">Public</div>
      </div>
    );
  };
  function AccessIndicator() {
    if (requestEdit) {
      return <RequestEdit></RequestEdit>
    } else if (adminEdit) {
      return <AdminEdit></AdminEdit>
    } else {
      return <PublicEdit></PublicEdit>
    }
  };

  function SelectFieldType(props: ISelectFieldTypeProps) {
    const [selectValue, setSelectValue] = useState(props.field_type);
    const onChange = (e: any) => {
      setSelectValue(e.target.value);
      setShowOptions(typesWithOptions.includes(e.target.value));
      props.changeFieldType(e.target.value);
    };
    return (
      <select onChange={onChange} value={selectValue}
        className="w-full border border-gray-300 rounded my-2 py-2 px-1">
        <option value="">--Choose an option--</option>
        <option value="short_text">Short Text</option>
        <option value="paragraph">Text Area</option>
        <option value="file_upload">File</option>
        <option value="email">Email</option>
        <option value="date">Date</option>
        <option value="time">Time</option>
        <option value="dropdown">Dropdown</option>
        <option value="checkbox">Checkbox</option>
        <option value="radio">Radio Button</option>
        <option value="number">Number</option>
      </select>
    );
  };
  function OptionsCheckbox() {
    return (
      <label className="flex mx-1">
        <div className="">
          <InputCheckbox checked={showOptions} onChange={(e) => setShowOptions(e.target.checked)}></InputCheckbox>
        </div>
        <div className="ml-1">
          add options
        </div>
      </label>
    );
  };
  function ListItem(props: any) {
    const placeholderString = `Option ${props.count}`;
    return (
      <li className="flex">
        <input type="text" className="w-full border border-gray-300 rounded my-1 py-1 px-1" placeholder={placeholderString} />
        <button onClick={() => setOptionCount(optionCount - 1)}>
          <Icon className="text-gray-500 text-2xl my-1 ml-1" name="close"></Icon>
        </button>
      </li>
    );
  };
  function FieldOptions() {
    if (addOptions) {
      return (
        <div className="my-2">
          <div className="text-sm">Options</div>
          <ul className="list-disc">
            {
              [...Array(optionCount)].map((_, index) => <ListItem key={index} count={index}></ListItem>)
            }
          </ul>
          <button onClick={() => setOptionCount(optionCount + 1)} className="text-purple-700 text-sm my-1">+ ADD MORE OPTIONS</button>
        </div>
      );
    } else {
      return <></>
    }
  };

  function updateField() {
    setSaving(true);
    if (auth.access_token){
      const apiUrl = `${RECORDS_RAILS_URL}/fields/${fieldId}`;
      const payload = {
        name: fieldName,
        field_type: fieldType,
        is_required: isRequired,
        order_id: orderId,
        can_employee_update: true,
        field_width: width,
      }
      axios.put(apiUrl, payload, 
        { headers: { Authorization: `Bearer ${auth.access_token}` } })
        .then((response) => {
          console.log(response)
          setSaving(false);
      });
    }
  }
  function onSaveRequest() {
    updateField();
    navigate(`/settings/fieldgroup/${props.field_group_id}`);
  }
  function deleteField() {
    if (auth.access_token){
      const headers = { 
        Authorization: `Bearer ${auth.access_token}`,
        Content_Type: 'application/json',
        Accept: 'application/json',
      };
      axios.delete(`${RECORDS_RAILS_URL}/fields/${fieldId}`, { headers })
        .then((response) => {
          console.log(response)
        });
    }
  }
  function onDeleteRequest() {
    deleteField();
    navigate(`/settings/fieldgroup/${props.field_group_id}`);
  }

  const mainContent = (
    <div className="">
      <div className="flex justify-end">
        {saving && <p>Saving...</p>}
        {props.onFieldDetail ? (
          <button type="button" onClick={() => onSaveRequest()}
            className="rounded bg-purple-700 text-white text-md ml-2 py-1 px-4">SAVE</button>
            ) : null }
      </div>
      <div className="flex w-full my-4 py-4">
        <div className="">
          {/* <button className="">
            <Icon className="mx-4 text-xl" name="unfold_more"></Icon>
          </button> */}
          <input type="number" onChange={(e) => setOrderId(parseInt(e.target.value))} value={orderId} placeholder="Order ID" 
            className="border border-t-0 border-l-0 border-r-0 rounded w-8 ml-1 border-gray-300"/>
        </div>
        <div className="w-full">
          <div className="flex w-full text-lg font-bold modal-parent">
            <input type="text" onChange={(e) => setFieldName(e.target.value)} value={fieldName} 
              placeholder="Field Name" className="border border-dashed border-purple-700 border-t-0 border-l-0 border-r-0 w-full" />
              <div className="ml-1">
                <button className="" type="button" onClick={() => setIsModalOpen(true)}>
                  <Icon name="more_vert"></Icon>
                </button>
                <Modal isOpen={isModalOpen} onCloseRequest={onModalCloseRequest}>
                  {props.onFieldDetail ? (
                    <DeleteModal deleteItem={onDeleteRequest}></DeleteModal>
                  ) : (
                    <div className='w-full text-sm font-medium text-gray-700'>
                      <button type="button"  onClick={() => navigate(`/settings/field/${fieldId}`)}
                          className='w-fit text-left'>Edit</button>
                    </div>
                  )}
                </Modal>
              </div>
          </div>
          <SelectFieldType field_type={fieldType} changeFieldType={setFieldType}></SelectFieldType>
          <div className="flex justify-between w-full text-gray-500">
            <div className="flex">
              <label className="flex mr-1">
                <div className="">
                  <InputCheckbox checked={isRequired} onChange={(e) => setIsRequired(e.target.checked)}></InputCheckbox>
                </div>
                <div className="ml-1">
                  required
                </div>
              </label>
              {addOptions && <OptionsCheckbox></OptionsCheckbox>}
            </div>
            <div className="flex">
              <AccessIndicator></AccessIndicator>
            </div>
          </div>
          {showOptions && <FieldOptions></FieldOptions>}
        </div>
      </div>
    </div>
  );

  var content;
  if (width === "thirds") {
      content = <div className="col-span-2">
          {mainContent}
      </div>
  } else if (width === "half") {
    content = <div className="col-span-3">
      {mainContent}
    </div>
  } else {
    content = <div className="col-span-6">
      {mainContent}
    </div>
  }

  return content;
}